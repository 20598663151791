import { Field, Form } from "react-final-form"
import { axios_server } from "../../Client/client.js"
import { FormInputField } from "../../Components/FormInputField.js"
import './style.css'
import '../../Style/forms.css'
import ReactMarkdown from 'react-markdown'
import React, { useMemo, useState } from 'react'

export const AdminView = (props) => {

    const [accountList, setAccountList] = useState([])

    const updateAccountEmail = (index, email) => {
        setAccountList(prevAccountList => {
            const updatedAccountList = prevAccountList.map((account, i) => {
            if (i === index) {
                return {...account, epost: email}
            } else {
                return account
            }
        })
            return updatedAccountList
        })
    }

    const initialValues = {}
    const onSubmit = async(values) => {
        const orgnr = values.orgnr
        axios_server.get('/admin/accounts', {'params': {orgnr}}).then(res => {
            try {
                const accounts = res.data?.accounts
                if(accounts)
                    setAccountList(accounts)
            } catch (error) {
                alert("Kunde inte hämta konton på det organisationsnumret")
            }
        })
    }

    return <>

        <div id="page-content-container">
            <h1>Granska Användare</h1>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                render={(formProps) => (
                    <>
                        <span>organisationsnummer</span>
                        <br/>
                        <Field
                            name="orgnr"
                            component="input"
                            type="text"
                            placeholder=""
                        />
                        <button type="button" onClick={() => {onSubmit(formProps.values)}}>SÖK</button>
                    </>
                )}
            />

            {
                accountList.map((account, index) => {
                    return <User account={account} index={index} updateAccountEmail={updateAccountEmail}/>
                })
            }
            

        </div>

    </>
}

const User = ({ account, index, updateAccountEmail }) => {
    const [hide, setHide] = useState(true)
    const toggleHide = e => {
        setHide(!hide)
    }

    return (
        <>
            <UserHeader name={account.webUser} toggleHide={toggleHide}/>
            <UserBody account={account} hide={hide} index={index} updateAccountEmail={updateAccountEmail}/>
        </>
    )
}

const UserHeader = ({ name, toggleHide }) => {
    return <div className="receiptlist-header" >
        <input type="hidden" value="Visa" />
        <input type="button" className="normal-button-blue" value="Visa" onClick={(e) => toggleHide()} />
        {name}
        <div style={{ clear: "both" }}></div>
    </div>
}



const UserBody = ({account, hide, index, updateAccountEmail}) => {

    const initialValues = {
        
    }

    const [editEmail, setEditEmail] = useState(false)

    const  onChangeEmail = async(values) => {
        setEditEmail(false);
        axios_server.put('/admin/changeEmail', {webUser: account.webUser, email: values.email}).then(res => {
            updateAccountEmail(index, values.email)
        })
    }

    const onAbortChangeEmail = async(values) => {
        setEditEmail(false);
    }

    const onSubmit = async(values) => {
        alert(values)
    }

    if (hide) return null
    return(
    <>
        <div className="receiptlist-body" style={{ paddingTop: "0px", borderTop: "none", paddingBottom: "0px", borderBottom: "none", overflow: "hidden", visibility: "visible", zoom: "1", opacity: "1" }}>
            <div className="receiptlist-body-inner">
                <div className="receiptlist-body-inner-printable">
                    <Form
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        onChangeEmail={onChangeEmail}
                        mutatos={{
                            setNewEmail: (args, state, utils) => {
                                
                            }
                        }}
                        render={(formProps) => (
                            <>
                                <form>
                                    <dl>
                                        <dt>användarnamn</dt>
                                        <dd>{account.webUser || ''}</dd>

                                        {
                                            !account.webAktiveringskod ?
                                            <>
                                                <dt>epost</dt>
                                                <dd>
                                                {
                                                editEmail ? 
                                                    <>
                                                        <Field
                                                            name="email"
                                                            component="input"
                                                            type="text"
                                                            placeholder={account.epost}
                                                        />
                                                        
                                                        <button type="button" onClick={() => {onChangeEmail(formProps.values)}}>Spara</button>
                                                        <button type="button" onClick={() => {onAbortChangeEmail(formProps.values)}}>Avbryt</button>
                                                    </> 
                                                    :
                                                    <>
                                                        <span>{account.epost}</span>
                                                        <button type="button" onClick={() => {setEditEmail(true)}}>Redigera</button>
                                                        
                                                        
                                                    </>
                                                }
                                                </dd>
                                                <dt>senast inloggad</dt>
                                                <dd>{account.lastSuccessfulLoginTime || ''}</dd>
                                            </> 
                                            :
                                            <>
                                                <dt>aktiveringskod</dt>
                                                <dd>{account.webAktiveringskod || ''}</dd>
                                            </>
                                        }
                                        

                                        

                                        <dt>status</dt>
                                        <dd>{account.status || ''}</dd>

                                    </dl>
                                </form>
                            </>
                        )}
                    />
                </div>
            </div>
        </div>
    </>
    )
}