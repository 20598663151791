import React, { useState, useMemo, useEffect } from 'react';
import './App.css';

import { BrowserRouter, Routes, Route, useRouteError } from 'react-router-dom'

import Layout from './Pages/Layout.js';
import StartPage from './Pages/Start.js';
import Home from './Pages/Home.js';
import Posts from './Components/Posts.js'
import { UserContext } from './Client/UserContext.js';
import { PopupMessageContext } from './Client/PopupMessageContect.js'
import NoMatch from './Pages/NoMatch.js';
import { IndexPHP } from './Pages/indexPHP.js';
import { getSessionInfo } from './Client/client.js';

function App() {
  const [user, setUser] = useState(null)
  const [popupMessage, setPopupMessage] = useState({info: '', error: ''})
  const providerValue = useMemo(() => ({ user, setUser }), [user, setUser])
  const providerValue2 = useMemo(() => ({ popupMessage, setPopupMessage }), [popupMessage, setPopupMessage])
  useEffect(() => {
    async function fetchData() {
      const jwtPayload = await getSessionInfo()
      setUser(jwtPayload)
    }
    fetchData()
  }, [])
  return (
    <BrowserRouter>
      <UserContext.Provider value={providerValue}>
        <PopupMessageContext.Provider value={providerValue2}>
          <Routes>
            <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
              <Route index element={<StartPage />} errorElement={<ErrorBoundary />} />
              <Route path="test" element={<Home />} errorElement={<ErrorBoundary />} />
              <Route path="posts" element={<Posts />} errorElement={<ErrorBoundary />} />
              <Route path="index.php" element={<IndexPHP />} errorElement={<ErrorBoundary />} />
              <Route path="index.php/:option/:alias" element={<IndexPHP />} />
              <Route path="index.php/:alias" element={<IndexPHP />} />
              <Route path="*" element={<NoMatch />} />

            </Route>
          </Routes >
        </PopupMessageContext.Provider>
      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}