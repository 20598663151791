import axios from "axios";

import Cookies from 'universal-cookie'

const cookies = new Cookies()
axios.defaults.baseURL = '/api/';
// axios calls are separated on auth and server calls
const axios_auth = axios.create()
export const axios_server = axios.create()
function refreshToken(error) {
    return axios_auth.post(`/auth/refresh`, {}, { withCredentials: true }).then((res) => {

        // status ok, set access token in cookie and run last request again
        let token = res.data['accessToken'];
        StoreToken(token);
        console.log(`Refresh: ${token}`);

        return axios_server.request(error.config);

    }, (error2) => {

        // refresh token got 401
        return Promise.reject(error);

    });
}
axios_server.interceptors.request.use(
    async config => {
        let accessToken = cookies.get('AccessToken')
        if (accessToken !== undefined) {
            config.headers.Authorization = `Bearer ${cookies.get('AccessToken')}`
        }

        return config;
    },
    error => {
        Promise.reject(error)
    }
)

axios_server.interceptors.response.use(null, (error) => {
    // We got 401, refresh token
    if (error.config && error.response && error.response.status === 401) {
        return refreshToken(error)
    }
    throw error
})

// auth
export const login = async (username, password) => {

    try {
        const res = await axios_auth.post(`/auth/login`, { 'username': username, 'password': password }, { withCredentials: true })
        const token = res.data['accessToken']
        const jwtPayload = StoreToken(token)
        console.log(`Login: ${JSON.stringify(jwtPayload, null, 2)}`)
        return {jwt: jwtPayload, status: 1}
    } catch (error) {
        const message = error.response?.data?.message
        return {message: message ? message : "Någonting gick fel, försök igen senare", status: 0}
    }
    
}
// auth
export const getSessionInfo = async (username, password) => {
    return axios_auth.post(`/auth/refresh`, {}, { withCredentials: true })
        .then((res) => {
            const token = res.data['accessToken'];
            const jwtPayload = StoreToken(token)
            console.log(`Login: ${JSON.stringify(jwtPayload, null, 2)}`)
            return jwtPayload
        })
        .catch(err => {
            console.log(err)
        })
}
// auth
export const logout = async () => {
    return axios_auth.delete(`/auth/logout`, { withCredentials: true }).then((res) => {
        cookies.remove("AccessToken", { path: '/' })
        console.log('Logout)')
        return null
    })
}

function b64_to_utf8( str ) {
    return decodeURIComponent(escape(window.atob( str )));
}

const StoreToken = (token) => {

    const temp = token.split('.')[1]
    const jwtPayload = JSON.parse(b64_to_utf8(temp));
    const exp = new Date(new Date().getTime() + (jwtPayload.exp / 1000) - 5) || new Date(new Date().getTime() + 3 * 1000);
    cookies.set('AccessToken', token, { sameSite: 'strict', path: '/', expires: exp });
    return jwtPayload
}
