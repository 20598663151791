import React from 'react'
import { useState, useEffect, useContext } from 'react'
import '../../Style/forms.css'
import './monetform.css'
import './webform.css'
import './webform2.0.css'
import './print.css'
import './warning.css'
import { Form, Field, FormSpy } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import setFieldData from 'final-form-set-field-data'
import { WebFormFloating } from './WebFormFloating.js'
import { DebugComponent } from '../../Components/DebugComponent.js'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../Client/UserContext.js'

import { PersGrid } from './PersGrid.js'
import { axios_server } from '../../Client/client.js'
import { composeValidators, validateFullName, validateMoneySum, validatePersonalIdentityNumber, validateProfessionCode, validateTimeHours } from './validators.js'
import texts from './texts.js'
import muncipality_data from './data/muncipality_data.json'
import FileField from "./FileField.tsx";

import validators from './validators.js'

import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import { FORM_ERROR } from 'final-form'

// this is the pre-warning
const disable_pre_warning = true
const disable_post_warning = false

const PrintPersonApprentice = ({ person }) => {
    
    const Art01 = person.Larlingstimmar?.Art01 || '0'
    const Art02 = person.Larlingstimmar?.Art02 || '0'
    const Art03 = person.Larlingstimmar?.Art03 || '0'
    const Art04 = person.Larlingstimmar?.Art04 || '0'
    const Art05 = person.Larlingstimmar?.Art05 || '0'
    const Art06 = person.Larlingstimmar?.Art06 || '0'
    const Art07 = person.Larlingstimmar?.Art07 || '0'
    const Art08 = person.Larlingstimmar?.Art08 || '0'
    const Art09 = person.Larlingstimmar?.Art09 || '0'
    const Art10 = person.Larlingstimmar?.Art10 || '0'
    const Art11 = person.Larlingstimmar?.Art11 || '0'

    return (
        <div class="webform-print-content-row">
            <div style={{'padding': '0 4%', 'width': '90%'}}>
                <div style={{'textAlign': 'center', 'line-height': '2em'}}>
                    LÄRLINGSTIMMAR
                    <br/>
                    <div style={{'textAlign': 'left', 'float': 'left'}}>
                        <span style={{'font-weight': 'bold'}}>Art</span>
                        <br/>
                        Invändigt &gt; Snickerier &gt; Underbehandling
                        <br/>
                        Invändigt &gt; Snickerier &gt; Målning
                        <br/>
                        Invändigt &gt; Tak &amp; väggar &gt; Underbehandling
                        <br/>
                        Invändigt &gt; Tak &amp; väggar &gt; Målning
                        <br/>
                        Invändigt &gt; Tak &amp; väggar &gt; Tapetsering
                        <br/>
                        Invändigt &gt; Tak &amp; väggar &gt; Vävsättning
                        <br/>
                        Utvändigt &gt; Trä &amp; mineraliska ytor &gt; Underbehandling
                        <br/>
                        Utvändigt &gt; Trä &amp; mineraliska ytor &gt; Målning
                        <br/>
                        Utvändigt &gt; Fönster &gt; Underbehandling
                        <br/>
                        Utvändigt &gt; Fönster &gt; Målning
                        <br/>
                        Övrigt
                        <br/>
                    </div>
                    <div style={{'textAlign': 'right', 'float': 'right', 'padding-left': '24px'}}>
                        <span style={{'font-weight': 'bold'}}>
                            Timmar
                        </span>
                        <br/>{Art01}<br/>{Art02}<br/>{Art03}<br/>{Art04}<br/>{Art05}<br/>{Art06}<br/>{Art07}<br/>{Art08}<br/>{Art09}<br/>{Art10}<br/>{Art11}<br/>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PrintPerson = ({ orgname, orgno, date, person }) => {

    const Personnummer = person.Personnummer || '-'
    const Namn = person.Namn || ''
    const ArbetadeTimmarTidlon = person.ArbetadeTimmarTidlon || '0'
    const LonesummaTidlon = person.LonesummaTidlon || '0'
    const ArbetadeTimmarAckord = person.ArbetadeTimmarAckord || '0'
    const LonesummaAckord = person.LonesummaAckord || '0'
    const AvtalsenligManadslon = person.AvtalsenligManadslon || '0'
    const OBTillagg = person.OBTillagg || '0'
    const Overtidstimmar = person.Overtidstimmar || '0'
    const Overtidstillagg = person.Overtidstillagg || '0'
    const ArbetadeTimmarHelglon = person.ArbetadeTimmarHelglon || '0'
    const LonesummaHelglon = person.LonesummaHelglon || '0'
    const TimmarBetaldledighet = person.TimmarBetaldledighet || '0'
    const LonesummaBetaldledighet = person.LonesummaBetaldledighet || '0'
    const TimmarArbetstidsforkortning = person.TimmarArbetstidsforkortning || '0'
    const SummaArbetstidsforkortning = person.SummaArbetstidsforkortning || '0'
    const Yrkeskod = person.Yrkeskod || ' - '

    const IsApprentice = Yrkeskod ? ["181", "182", "183", "184", "185", "188"].includes(Yrkeskod) : false

    return (
    
        <>
            <div class="webform-print-co-header">
                <div style={{'float': 'left', 'line-height': '18px'}}> 
                    <span class="webform-print-header-emph">Företag</span>
                    <br/>
                    <span class="webform-print-header-emph">Organisationsnummer</span>
                    <br/>
                    <span class="webform-print-header-emph">Utbetalningsdatum</span>
                </div>  
                <div style={{'float': 'left', 'padding-left': '12px', 'line-height': '18px'}}>{orgname}{/*Testföretaget AB*/}<br/>{orgno}{/* 7907179191 */}<br/>{date}  {/* 2023-04-25 */}</div>
                <div style={{'clear': 'both'}}></div>
            </div>

            <div class="webform-print-pers-header"> 
                <div class="webform-print-pers-header-content-left">
                    <span class="webform-print-header-emph">Namn&nbsp;&nbsp;</span>{Namn}
                </div>
                <div class="webform-print-pers-header-content-right">
                    <span class="webform-print-header-emph">Personnummer&nbsp;&nbsp;</span>{Personnummer}
                </div>
                <div style={{'clear': 'both'}}></div>
            </div>
            <div class="webform-print-content-row">
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">TIDLÖN</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Summa</div>
                        <div class="webform-print-datafield">{LonesummaTidlon}</div>
                    </div>
                    <div class="webform-print-group-member-right">
                        <div class="webform-print-datafield-header">Timmar</div>
                        <div class="webform-print-datafield">{ArbetadeTimmarTidlon}</div>
                    </div>
                </div>
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">ACKORD</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Summa</div>
                        <div class="webform-print-datafield">{LonesummaAckord}</div>
                    </div>
                    <div class="webform-print-group-member-right">
                        <div class="webform-print-datafield-header">Timmar</div>
                        <div class="webform-print-datafield">{ArbetadeTimmarAckord}</div>
                    </div>
                </div>
                <div style={{'clear': 'both'}}></div>
            </div>
            <div class="webform-print-content-row">
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">&nbsp;</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Månadslön</div>
                        <div class="webform-print-datafield">{AvtalsenligManadslon}</div>
                    </div>
                </div>
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">ÖVERTID</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Summa tillägg</div>
                        <div class="webform-print-datafield">{Overtidstillagg}</div>
                    </div>
                    <div class="webform-print-group-member-right">
                        <div class="webform-print-datafield-header">Timmar</div>
                        <div class="webform-print-datafield">{Overtidstimmar}</div>
                    </div>
                </div>
                <div style={{'clear': 'both'}}></div>
            </div>
            <div class="webform-print-content-row">
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">HELGLÖN</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Summa</div>
                        <div class="webform-print-datafield">{LonesummaHelglon}</div>
                    </div>
                    <div class="webform-print-group-member-right">
                        <div class="webform-print-datafield-header">Timmar</div>
                        <div class="webform-print-datafield">{ArbetadeTimmarHelglon}</div>
                    </div>
                </div>
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">BETALD LEDIGHET</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Summa</div>
                        <div class="webform-print-datafield">{LonesummaBetaldledighet}</div>
                    </div>
                    <div class="webform-print-group-member-right">
                        <div class="webform-print-datafield-header">Timmar</div>
                        <div class="webform-print-datafield">{TimmarBetaldledighet}</div>
                    </div>
                </div>
                <div style={{'clear': 'both'}}></div>
            </div>
            <div class="webform-print-content-row">
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">ARBETSTIDSFÖRKORTNING</div>
                    <div class="webform-print-group-member-left">
                        <div class="webform-print-datafield-header">Summa</div>
                        <div class="webform-print-datafield">{SummaArbetstidsforkortning}</div>
                    </div>
                    <div class="webform-print-group-member-right">
                        <div class="webform-print-datafield-header">Timmar</div>
                        <div class="webform-print-datafield">{TimmarArbetstidsforkortning}</div>
                    </div>
                </div>
                <div class="webform-print-content-halfpage">
                    <div class="webform-print-group-header">&nbsp;</div>
                    <div class="webform-print-group-member-full">
                        <div class="webform-print-datafield-header">Yrkeskod</div>
                        <div class="webform-print-datafield-full">{Yrkeskod/* 110 Utlärd målare */}</div>
                    </div>
                </div>
                <div style={{'clear': 'both'}}></div>
            </div>
            { IsApprentice ? <PrintPersonApprentice person={person}/> : <></>}
            <div class="webform-print-content-row">
                <div style={{'padding': '0 4%', 'width': '90%'}}>
                </div>
            </div>
        </>
    
    )
}

const PrintComponent = React.forwardRef((props, ref) => {

    const { user, setUser } = useContext(UserContext)

    const date = props.formProps.values.date || '-'
    const orgno = user?.orgno || '-'
    const orgname = '-'

    return (
        <div ref={ref}>
            
            
            <div class="printdiv" id="printdiv">
                
                {
                    props.formProps.values.Personer.map((person) => {
                        //console.log(JSON.stringify(person))
                        return (
                            <div class="webform-print-page">
                                <PrintPerson orgname={orgname} orgno={orgno} date={date} person={person}/>
                            </div>
                        )
                    })
                }
                    
                
            </div>
        </div>
    
    
    )
})

const ReceiptError = async(props) => {

    
}

export const UploadForm = ({...items}) => {
    
    const [defaultMuncipality, setDefaultMuncipality] = useState(null)
    const [myReceiptErrorList, setMyReceiptErrorList] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [isProcessingFinal, setIsProcessingFinal] = useState(false)
    
    const [displayPreWarning, setDisplayPreWarning] = useState(false)
    const [pendingSubmit, setPendingSubmit] = useState(null)

    const [displayPostWarning, setDisplayPostWarning] = useState(false)
    const [pendingRedirect, setPendingRedirect] = useState(null)

    const navigate = useNavigate();
    const update_code = items?.['update_code']

    useEffect(() => {
        axios_server.get('/mp/mpb016/getCountyCode')
        .then(res => {
            if(res.data.county)
                setDefaultMuncipality(res.data.county)
        })
        .catch(error => {
            console.error(error)
        })
    }, [])

    const initialValues = {
        helpTexts: false,
        date: new Date(new Date().getFullYear(), (new Date().getDate() > 14) ? new Date().getMonth() : new Date().getMonth() - 1, 26).toISOString().substring(0, 10),
        Personer: [{ key: uuidv4() }],
        replacement_code: update_code,
        LanOchKommun: defaultMuncipality
    }
    const WarningEngine = ({ setFieldData }) => {
        return (
            <FormSpy
                subscription={{ values: true, active: true }}
                onChange={({ active, values }) => {
                    
                    if(typeof active === 'string' && active.startsWith('Personer[')) {
                        const terms = active.split('.')
                        
                        const name = terms[0]
                        const person = eval(`values.${name}`)
                        
                        setFieldData(`${name}.Personnummer`, {
                            ...composeValidators([], validatePersonalIdentityNumber)(person.Personnummer)
                        })
                        setFieldData(`${name}.Namn`, {
                            ...composeValidators([], validateFullName)(person.Namn)
                        })
                        setFieldData(`${name}.Yrkeskod`, {
                            ...texts.yrkeskod,
                            ...composeValidators([], validateProfessionCode)(person.Yrkeskod)
                        })
                        setFieldData(`${name}.ArbetadeTimmarTidlon`, {
                            header: texts.tidlon.header,
                            ...texts.tidlon.hour,
                            ...composeValidators([], validateTimeHours)(person.ArbetadeTimmarTidlon)
                        })
                        setFieldData(`${name}.LonesummaTidlon`, {
                            header: texts.tidlon.header,
                            ...texts.tidlon.summary,
                            ...composeValidators([], validateMoneySum)(person.LonesummaTidlon)
                        })
                        setFieldData(`${name}.ArbetadeTimmarAckord`, {
                            header: texts.ackord.header,
                            ...texts.ackord.hour,
                            ...composeValidators([], validateTimeHours)(person.ArbetadeTimmarAckord)
                        })
                        setFieldData(`${name}.LonesummaAckord`, {
                            header: texts.ackord.header,
                            ...texts.ackord.summary,
                            ...composeValidators([], validateMoneySum)(person.LonesummaAckord)
                        })
                        setFieldData(`${name}.AvtalsenligManadslon`, {
                            ...texts.manadslon,
                            ...composeValidators([], validateMoneySum)(person.AvtalsenligManadslon)
                        })
                        setFieldData(`${name}.Overtidstimmar`, {
                            header: texts.overtid.header,
                            ...texts.overtid.hour,
                            ...composeValidators([], validateTimeHours)(person.Overtidstimmar)
                        })
                        setFieldData(`${name}.Overtidstillagg`, {
                            header: texts.overtid.header,
                            ...texts.overtid.summary,
                            ...composeValidators([], validateMoneySum)(person.Overtidstillagg)
                        })
                        setFieldData(`${name}.ArbetadeTimmarHelglon`, {
                            header: texts.helglon.header,
                            ...texts.helglon.hour,
                            ...composeValidators([], validateTimeHours)(person.ArbetadeTimmarHelglon)
                        })
                        setFieldData(`${name}.LonesummaHelglon`, {
                            header: texts.helglon.header,
                            ...texts.helglon.summary,
                            ...composeValidators([], validateMoneySum)(person.LonesummaHelglon)
                        })
                        setFieldData(`${name}.TimmarBetaldledighet`, {
                            header: texts.betaldLedighet.header,
                            ...texts.betaldLedighet.hour,
                            ...composeValidators([], validateTimeHours)(person.TimmarBetaldledighet)
                        })
                        setFieldData(`${name}.LonesummaBetaldledighet`, {
                            header: texts.betaldLedighet.header,
                            ...texts.betaldLedighet.summary,
                            ...composeValidators([], validateMoneySum)(person.LonesummaBetaldledighet)
                        })
                        setFieldData(`${name}.TimmarArbetstidsforkortning`, {
                            header: texts.arbetstidsforkortning.header,
                            ...texts.arbetstidsforkortning.hour,
                            ...composeValidators([], validateTimeHours)(person.TimmarArbetstidsforkortning)
                        })
                        setFieldData(`${name}.SummaArbetstidsforkortning`, {
                            header: texts.arbetstidsforkortning.header,
                            ...texts.arbetstidsforkortning.summary,
                            ...composeValidators([], validateMoneySum)(person.SummaArbetstidsforkortning)
                        })

                    }
                    
                }}
            />
        );
    }
    const Fields = ({
        names,
        subscription,
        fieldsState = {},
        children,
        originalRender
    }) => {
        if (!names.length) {
            return (originalRender || children)(fieldsState);
        }
        const [name, ...rest] = names;
        return (
            <Field name={name} subscription={subscription}>
                {fieldState => (
                    <Fields
                        names={rest}
                        subscription={subscription}
                        originalRender={originalRender || children}
                        fieldsState={{ ...fieldsState, [name]: fieldState }}
                    />
                )}
            </Field>
        );
    };

    const validate = (values) => {
        
        const people = values.Personer

        const errors_person_list = []
        let errors_num = 0


        for(let i=0;i<people.length;i++) {
            
            let person_errors = []

            if(typeof values.date === 'string') {
                const year = Number(values.date.substring(0, 4))
                // yyyy-mm
                if(new Date(values.date.substring(0, 7)) >= new Date("2024-02") && people[i].Yrkeskod === '130') {
                    person_errors.push({tag: 'Yrkeskod', error: `Yrkeskod 130 ej giltig för ${year}`})
                }
            }
            
            

            person_errors.push(validators.composeValidators(['failed'], validators.required, validators.validatePersonalIdentityNumber)(people[i].Personnummer))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'Personnummer';
            }

            person_errors.push(validators.composeValidators(['failed'], validators.required, validators.validateFullName)(people[i].Namn))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'Namn';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateProfessionCode)(people[i].Yrkeskod))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'Yrkeskod';
            }
            
            //Tidlön
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].LonesummaTidlon))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'LonesummaTidlon';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateTimeHours)(people[i].ArbetadeTimmarTidlon))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'ArbetadeTimmarTidlon';
            }
            //ackord
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].LonesummaAckord))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'LonesummaAckord';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateTimeHours)(people[i].ArbetadeTimmarAckord))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'ArbetadeTimmarAckord';
            }
            //Månadslön
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].AvtalsenligManadslon))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'AvtalsenligManadslon';
            }
            //Övertid
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].Overtidstillagg))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'Overtidstillagg';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateTimeHours)(people[i].Overtidstimmar))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'Overtidstimmar';
            }
            //Helglön
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].LonesummaHelglon))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'LonesummaHelglon';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateTimeHours)(people[i].ArbetadeTimmarHelglon))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'ArbetadeTimmarHelglon';
            }
            //Betald ledighet
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].LonesummaBetaldledighet))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'LonesummaBetaldledighet';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateTimeHours)(people[i].TimmarBetaldledighet))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'TimmarBetaldledighet';
            }
            //Arbetstidsförkortning
            person_errors.push(validators.composeValidators(['failed'], validators.validateMoneySum)(people[i].SummaArbetstidsforkortning))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'SummaArbetstidsforkortning';
            }
            person_errors.push(validators.composeValidators(['failed'], validators.validateTimeHours)(people[i].TimmarArbetstidsforkortning))
            if (person_errors[person_errors.length-1]) {
                person_errors[person_errors.length-1].tag = 'TimmarArbetstidsforkortning';
            }

            if(people[i].Larlingstimmar){
                
                let sum_hours = 0
                
                const parsed_tidlon = Number(String(people[i].ArbetadeTimmarAckord).replace(/,/g, '.').replace(/\s/g, ""))
                const parsed_ackord = Number(String(people[i].ArbetadeTimmarTidlon).replace(/,/g, '.').replace(/\s/g, ""))
                const parsed_overtid = Number(String(people[i].Overtidstimmar).replace(/,/g, '.').replace(/\s/g, ""))
                const parsed_helglon = Number(String(people[i].ArbetadeTimmarHelglon).replace(/,/g, '.').replace(/\s/g, ""))
                const parsed_betaldledighet = Number(String(people[i].TimmarBetaldledighet).replace(/,/g, '.').replace(/\s/g, ""))
                const parsed_arbetstidsforkortning = Number(String(people[i].TimmarArbetstidsforkortning).replace(/,/g, '.').replace(/\s/g, ""))

                
                if(!isNaN(parsed_ackord))
                    sum_hours += parsed_ackord
                if(!isNaN(parsed_tidlon))
                    sum_hours += parsed_tidlon
                if(!isNaN(parsed_overtid))
                    sum_hours += parsed_overtid
                if(!isNaN(parsed_helglon))
                    sum_hours += parsed_helglon
                if(!isNaN(parsed_betaldledighet))
                    sum_hours += parsed_betaldledighet
                if(!isNaN(parsed_arbetstidsforkortning))
                    sum_hours += parsed_arbetstidsforkortning

                //console.log(sum_hours)
                let sum_apprentice_hours = 0

                for(let apprentice_code = 1; apprentice_code <=11; apprentice_code++) {
                    
                    const art_code = "Art" + ((apprentice_code < 10) ? "0" : "") + apprentice_code;

                    const art = people[i].Larlingstimmar[art_code]
                    if(art) {
                        let a = validators.composeValidators(['failed'], validators.validateTimeHours)(art)
                        const parsed_art = Number(String(art).replace(/,/g, '.').replace(/\s/g, ""))
                        if(!isNaN(parsed_art)) {
                            sum_apprentice_hours += parsed_art
                        }
                        person_errors.push(a)
                        if (person_errors[person_errors.length-1]) {
                            person_errors[person_errors.length-1].tag = `Larlingstimmar.${art_code}`;
                        }
                    }
                }

                if(sum_apprentice_hours > sum_hours)
                    person_errors.push({result: '', error: 'Lärlingstimmar överstiger antal timmar (Tidlön + Ackord)', hint: '', tag: 'Larlingstimmar'})

            }
            
            person_errors = person_errors.filter(function(value) {
                return value !== undefined
            })
            
            errors_num += person_errors.length
            errors_person_list.push(person_errors)
        }

        if(errors_num === 0)
            return {}
        return {errors_person_list: errors_person_list, errors_num: errors_num}
    }

    const GetErrorList = async (filename) => {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `/mp/mpa002/getReceiptFile?filename=${filename}`,
            headers: { 
                'Content-Type': 'application/json'
            }
        };
    
        const res = await axios_server.request(config)
        const num_error = res.data?.file?.antalFel
        //console.log(res.data?.file)
    
        // skipping date for now, it seems that the files date format can have bad format!
    
        /*
        const dateString = res.data?.file?.datum
        const timeString = res.data?.file?.klockslag
    
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const hour = timeString.substring(0, 2);
        const minute = timeString.substring(2, 4);
        const second = timeString.substring(4, 6);
        const dateTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
        const formattedDateTime = dateTime.toISOString().replace('T', ' ').substring(0, 19);
        console.log(formattedDateTime)
        */
    
        if( num_error === "0" ) {
            console.log("GetErrorList", "0")
            return null
        } else if( num_error === "1" ){
            console.log("GetErrorList", "1")
            const error_list = [res.data?.file?.fel_lista?.fel]
            return error_list
        } else {
            console.log("GetErrorList", "many")
            const error_list = res.data?.file?.fel_lista?.fel
            return error_list
        }
    
    }

    // Used when submitting a file
    const onSubmitFile = async (values) => {

        

        setIsProcessing(true)
        try {
            const file = values["file"]["0"]
            const filename = file.name
            const reader = new FileReader()
            reader.readAsText(file, 'ISO-8859-1')
            reader.onload = function (evt) {
                const file_data = evt.target.result
                const url = "/mp/mpa002/upload"
                const data = { "file": file_data, "filename": filename, replacement_code:  values.replacement_code}
                const config = {}
                console.log(file_data)

                let warning = false
                if(false) {
                    const pattern = new RegExp(`${'<yrkeskod>'}(.*?)${'</yrkeskod>'}`, 'gs')
                    const matches = []
                    let match
                    while ((match = pattern.exec(file_data.toLowerCase())) !== null) {
                        matches.push(Number(match[1]))
                    }
    
                    warning = matches.includes(130)
                }

                //console.log(matches, warning)
                
                if(warning && !disable_pre_warning) {
                    setDisplayPreWarning(true)
                    setPendingSubmit(data)
                } else {
                    onSubmitFinal(data)
                }

            }
        } catch (error) {
            alert(`There was an error, please try again later. ${error}`)
            setIsProcessing(false)
        }


    }

    // Used when submitting form normally
    const onSubmit = async (values) => {

        //return {[FORM_ERROR]: "hej"}

        const url = "/mp/mpa002/upload"
        //const data = values
        const {file, ...data} = values
        const config = {}

        let warning = false
        if(false) {
            if(data.Personer) {
                for(let i=0;i<data.Personer.length;i++) {
                    if(data.Personer[i].Yrkeskod === '130') {
                        warning = true
                    }
                }
            }
        }
        

        if(warning && !disable_pre_warning) {
            setDisplayPreWarning(true)
            setPendingSubmit(data)
        } else {
            onSubmitFinal(data)
        }
        
        

        //return {[FORM_ERROR]: undefined} was this used?
    }

    const Redirect = async (receipt) => {
        navigate('/index.php/kvittenser', { state: {title: 'Kvittenser', link: `index.php?option=com_monetform&view=receiptlist&receipt=${receipt}`} })
    }

    const onSubmitFinal = async (data) => {

        setIsProcessingFinal(true)
        const url = "/mp/mpa002/upload"

        try {
            const response = await axios_server.post(url, data, {})
            console.log(JSON.stringify(response))

            if(response.data.receipt !== undefined && response.data.receipt !== null) {
                if(response.data.warning) {
                    setDisplayPostWarning(true)
                    setPendingRedirect(response.data.receipt)
                } else {
                    console.log(response.data.receipt)
                    //navigate('/index.php/kvittenser', { state: {title: 'Kvittenser', link: `index.php?option=com_monetform&view=receiptlist&receipt=${response.data.receipt}`} })
                    Redirect(response.data.receipt)
                }

            }

        } catch (error) {
            const e = error.response?.data?.error
            if(e){
                alert(e)
            }
            
        }
        
        setPendingSubmit(null)
        setDisplayPreWarning(false)
        setIsProcessingFinal(false)
        setIsProcessing(false)
    }

    const cancelSubmit = async (data) => {
        setPendingSubmit(null)
        setDisplayPreWarning(false)
        setIsProcessingFinal(false)
        setIsProcessing(false)
    }

    const muncipailty_optgroups = muncipality_data.data.map((area) => {

        return (

            <optgroup key={area.name} label={area.name}>
                {area.muncipailties.map((muncipality) => {
                    return (
                        <option key={muncipality.value} value={muncipality.value}>{muncipality.value} {muncipality.name}</option>
                    )
                })}
            </optgroup>
        )

    })

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const ComponentTest = (props) => {
        const date = "" // disabled, it seems that files can have bad date format
        const divStyle1 = {
            float: 'left',
        }
        const divStyle2 = {
            'line-height': '32px',
            'padding-left': '76px'
        }
        const pStyle = {
            margin: 0,
            padding: 0
        }

        if(props.error_list === undefined || props.error_list === null){
            return <></>
        }
    return (
        <>
            <h1>Fel - Uppgifterna har inte lämnats</h1>
			<div style={divStyle1}><img alt="" src="https://www.maleriportalen.se/dev/components/com_monetform/images/error.png"/></div>
			<div class="monet">
                <div style={divStyle2}>
					<p style={pStyle}>Felet registrerades {date}</p>
					<p style={pStyle}>Bifoga den här listan om du kontaktar supporten.</p>
				</div>
                
					
				<h2>Fellista[{props.error_list === null ? 0 : props.error_list.length} fel]</h2>
                {props.error_list === null ? <></> : props.error_list.map((item, index) => {
                    console.log(item)
                    return <div class="monet-error"><h3>{index + 1}</h3><span class="monet-error-description">{item.feltext}</span></div>
                })}
			</div>
        </>
    )
    }
    
    return <>
        <Form
            keepDirtyOnReinitialize 
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            mutators={{
                setFieldData,
                ...arrayMutators,
            }}
            render={(formProps) => {
                const fieldArr = []
                formProps.values.Personer.forEach((person, index) => {
                    const name = `Personer[${index}]`
                    fieldArr.push(...[
                        `${name}.Personnummer`,
                        `${name}.Namn`,
                        `${name}.Yrkeskod`,
                        `${name}.ArbetadeTimmarTidlon`,
                        `${name}.LonesummaTidlon`,
                        `${name}.ArbetadeTimmarAckord`,
                        `${name}.LonesummaAckord`,
                        `${name}.OBTillagg`,
                        `${name}.AvtalsenligManadslon`,
                        `${name}.Overtidstimmar`,
                        `${name}.Overtidstillagg`,
                        `${name}.ArbetadeTimmarHelglon`,
                        `${name}.LonesummaHelglon`,
                        `${name}.TimmarBetaldledighet`,
                        `${name}.LonesummaBetaldledighet`,
                        `${name}.TimmarArbetstidsforkortning`,
                        `${name}.SummaArbetstidsforkortning`
                    ])
                })

                const openNewWindow = (url) => {
                    const width = window.screen.width;
                    const height = window.screen.height;
                    const features = `width=${width * 0.8},height=${height * 0.8}`;
                    window.open(url, '_blank', features);
                }

                const url = "https://www.maleriportalen.se/"
                
                return (
                    <>
                        {
                            displayPreWarning ? 
                        <div id="myModal" class="modal">
                            <div class="modal-content">
                                
                                <span class="close" onClick={() => {
                                    cancelSubmit()
                                }} disabled={isProcessingFinal}>&times;</span>
                                <h1>Varning</h1>
                                <p>Yrkeskod 130 kommer upphöra vid årsskiftet.</p>
                                <p>
                                    Klicka{' '}
                                    <a href = {url} target="_blank" onClick={() => {openNewWindow(url)}} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>här</a>
                                    {' '}för att läsa mer.
                                </p>

                                <button onClick={() => {
                                    onSubmitFinal(pendingSubmit)
                                }} disabled={isProcessingFinal}>Lämna</button>
                                <button onClick={() => {
                                    cancelSubmit()
                                }} disabled={isProcessingFinal}>Avbryt</button>
                            </div>
                        </div> : 
                        <></>                            
                        }
                        {
                            displayPostWarning ? 
                            <div id="myModal2" class="modal">
                                <div class="modal-content">
                                
                                    <h1>FÖR ER INFORMATION</h1>
                                    <p>Yrkeskod 130 kommer att fasas ut och upphöra att gälla per 2023-12-31.</p>
                                    <p>
                                        För mer information och vad som gäller i dess ställe kontakta:<br/>
                                        Centrala avtal,{' '}
                                        <a href = {'https://www.maleriforetagen.se/om-oss/medarbetare/'} target="_blank" onClick={() => {openNewWindow(url)}} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>https://www.maleriforetagen.se/om-oss/medarbetare/</a>
                                        {' '}, Stephan Edwinsson
                                    </p>
                                    <p>Hängavtal, er avdelning inom Målarna,<br/>
                                        Centrala avtal,{' '}
                                        <a href = {'https://www.malarna.nu/avdelningar/'} target="_blank" onClick={() => {openNewWindow(url)}} style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}>Avdelningar - Målarnas förbund (malarna.nu)</a>
                                        {' '}
                                    </p>

                                    <button onClick={() => {
                                        Redirect(pendingRedirect)
                                    }} disabled={false}>OK</button>
                                </div>
                            </div> : 
                            <></>
                        }
                        
                        <ComponentTest error_list={formProps.submitError}/>
                        <PrintComponent ref={componentRef} formProps = {formProps}/>
                        <form onSubmit={
                            event => {
                                formProps.handleSubmit(event)
                            }
                            
                            }
                            onKeyDown={
                                event => {
                                    if(event.key === 'Enter')
                                        event.preventDefault();
                                    //console.log(event)
                                }
                            } 
                            
                            action="" id="form-webform">
                            <div id="monet-uploadform-container">
                                <div id="monet-replace">
                                    {/*<form action="" id="webform-replace" >*/}
                                        <fieldset>
                                            <div className="monet-submit-group">
                                                <div className="monet-update-code">
                                                    <Field
                                                        name="replacement_code"
                                                        component="input"
                                                        type="text"
                                                    >

                                                    </Field>
                                                    <input type="hidden" id="update-code-source-hidden" defaultValue="" />
                                                    <input type="checkbox" disabled="disabled" id="do-update" />

                                                </div>
                                                <div className="monet-update-info">
                                                    Du kan ändra tidigare inskickade löneuppgifter en kort tid efter det att löneuppgifterna skickats.
                                                    <br /><br />
                                                    På kvittensen finns en <i>ersättningskod</i>. Fyll i <i>ersättningskoden</i> i fältet till höger.
                                                    <br /><br />
                                                    De tidigare inskickade löneuppgifterna kommer att ersättas i sin helhet med den fil du skickar eller
                                                    den data du anger i webbformuläret.
                                                </div>
                                                <div style={{ clear: 'both' }}></div>
                                            </div>
                                        </fieldset>
                                    {/*</form>*/}
                                </div>

                                <div id="formchooser-accordion">
                                    <div className="formchooser-header" id="formchooser-header-file"><input type="button" className="normal-button-blue" value="Öppna" /> Lämna via fil<div style={{ clear: 'both' }}></div></div>
                                    <div className="formchooser-body">
                                        <div className="formchooser-body-inner">
                                            <>
                                                <FileField name="file" /><br />
                                                <button type="button" disabled={isProcessing} onClick={() => {
                                                    onSubmitFile(formProps.values, formProps)
                                                }}>Skicka</button>
                                            </>
                                        </div>
                                    </div>

                                    <div className="formchooser-header" id="formchooser-header-webform"><input type="button" className="normal-button-blue" value="Öppna" /> Lämna via webbformulär<div style={{ clear: 'both' }}></div></div>
                                    <div className="formchooser-body">
                                        <div className="formchooser-body-inner">
                                            <div id="monet-webform">
                                                <>
                                                    <div className="outer-wrapper" id="webform-globaldata">
                                                        <div className="input-outer-wrapper input-outer-first-wrapper"><label>Utbetalningsdatum</label></div>
                                                        <div className="input-outer-wrapper input-wide-outer-wrapper"><label>Kommunkod</label></div>
                                                        <div style={{ clear: 'both' }}></div>
                                                        <div className="input-outer-wrapper input-outer-first-wrapper">
                                                            <Field className="input-text input-outer warn-validate-paydate-future warn-validate-paydate-past warn-validate-paydate-endofmonth warnOnly" name="date" id="webform_paydate" component="input" type="date" />
                                                            <input type="hidden" id="webform_paydate_old" />
                                                            <input type="hidden" id="webform_paydate_correct" value="yes" />
                                                        </div>
                                                        <div className="input-outer-wrapper input-wide-outer-wrapper">
                                                            <Field
                                                                className="input-select input-outer validate-municipality-code"
                                                                id="municipality_code"
                                                                component="select"
                                                                name="LanOchKommun"
                                                            >
                                                                <option value="">Välj kommunkod</option>
                                                                {muncipailty_optgroups}
                                                            </Field>
                                                            <input type="hidden" id="webform_plausibility_check_correct" value="no" />
                                                        </div>
                                                        <div style={{ clear: 'both' }}></div>
                                                    </div>
                                                    <div style={{ clear: 'both' }}></div>
                                                    <hr />
                                                    <div id="persgrid">

                                                        {/* Buttons on top  of container */}
                                                        <div className="webform-outer-commands">
                                                            <input type="button" className="webform-command-print webform-commands-leftmost" onClick={handlePrint} value="SKRIV UT" />
                                                            <Field name='helpTexts'>
                                                                {p => (
                                                                    <input type="button" className="webform-commands-rightmost webform-command-help-hide" onClick={e => p.input.onChange(!p.input.value)} value={p.input.value ? "DÖLJ HJÄLPTEXTER" : 'VISA HJÄLPTEXTER'} />
                                                                )}
                                                            </Field>
                                                        </div>
                                                        <div className="block-float-escape"></div>
                                                        <PersGrid {...formProps} />
                                                    </div>
                                                    <DebugComponent values={{ values: formProps.values }} />
                                                    <DebugComponent values={{ values: formProps.errors }} />
                                                    <WarningEngine setFieldData={formProps.form.mutators.setFieldData} />
                                                    <Fields names={fieldArr}>
                                                        {fieldsState => {
                                                            const data = Object.values(fieldsState).filter(f => f.meta.active)[0]
                                                            const metadata = data?.meta?.data || {}
                                                            const ele = document.getElementsByName(data?.input?.name)[0]
                                                            const showHelpTexts = () => {
                                                                if (metadata.result === 'failed' || metadata.result === 'hint') return false
                                                                return !formProps.values.helpTexts
                                                            }
                                                            return (
                                                                <>
                                                                    { false ? <pre>{JSON.stringify(data, undefined, 2)}</pre> : <></> }
                                                                    <WebFormFloating
                                                                        title={metadata.title}
                                                                        helptext={metadata.helptext}
                                                                        error={(metadata.result === 'failed') ? metadata.error : ''}
                                                                        warning={(metadata.result === 'hint') ? metadata.error : ''}
                                                                        hint={metadata.hint}
                                                                        hide={showHelpTexts()}
                                                                        relativeTo={ele}
                                                                    />
                                                                </>
                                                            )
                                                        }}
                                                    </Fields>
                                                    <button type="submit" disabled={formProps.submitting}>Lämna</button>
                                                    {/*

                                                This snippet can be used to skip validation for submit

                                                <button type="button" onClick={() => {
                                                    onSubmitNEW(formProps.values, formProps)
                                                }}>SUBMIT WITHOUT VALIDATION</button>

                                                */}

                                                </>
                                            </div>
                                            <div id="webform-infobox-outer">
                                                <div id="webform-infobox-inner"></div>
                                                <div className="webform-infobox-bottom"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="monet-uploadform-loading">
                                        <div className="loading-animation" id="loading-container"><div id="loading-glow"></div></div>
                                        <p id="monet-uploadform-loading-text" className="monet-uploadform-loading">&nbsp;&nbsp;Skickar&nbsp;&nbsp;&nbsp;</p>
                                    </div>
                                </div>
                            </div>

                        </form>
                        
                        {formProps.errors.errors_num ? 
                        <div id="monet-submit-warning">
                            Webbformuläret innehåller {formProps.errors.errors_num} fel
                            {
                                formProps.errors.errors_person_list?.map((person, index) => {
                                    //return <div>hej</div>
                                    return (
                                        <ul>{
                                            person.map((error) => {
                                                return <li> person {index + 1} - {error.tag} - {error.error} </li>
                                            })}
                                        </ul>
                                    )
                                })
                            }
                        </div>
                        : <> </>
                        }
                        

                                    
                    </>
                )
            }}
        />

    </>
}