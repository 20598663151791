import React from 'react';
import classNames from 'classnames';

/**
 * @param  {} {message}
 */
export const Message = ({ message }) => {
    if (!message)
        return null;
    const cls = classNames(
        'message',
        { message: message.result === 'ok' },
        { error: message.result === 'fail' },
        { notice: message.result !== 'ok' && message.result !== 'fail' }


    );
    return <div id="system-message-container">
        <dl id="system-message">
            <dt className="message">Meddelande</dt>
            <dd className={cls}>
                <ul>
                    <li>{message.Message||message.message}</li>
                </ul>
            </dd>
        </dl>
    </div>;
};
