import { useEffect, useState } from "react"
import { axios_server } from "../../Client/client.js";
import './kvartalsstatistik.css'

export const Statistik = (props) => {
    const [data, setData] = useState(null)
    const [period, setPeriod] = useState(null)
    useEffect(() => {
        Search();
    }, [])
    function Search() {
        axios_server.get(`/cms/stats/list`).then((res) => {
            setData(res?.data);
            const item = res.data[0]
            setPeriod(`${item.year}/${item.quarter}`)
        });
    }
    const SelectionChange = (e) => {
        setPeriod(e.target.value)
    }
    const options = data?.map((item, index) => <option key={`${item.year}/${item.quarter}`} value={`${item.year}/${item.quarter}`}>{`${item.year} Kvartal ${item.quarter}`}</option>)
    if (!data) return null
    return <>
        <h1>Kvartalsstatistik för beräkning av sjuklön</h1>
        <select defaultValue={period} onChange={(e) => {
            console.log('selection changed')
            SelectionChange(e)
        }}>
            {options}
        </select>
        <ShowTable period={period} />
    </>
}
const ShowTable = ({ period }) => {
    const [data, setData] = useState(null)
    useEffect(() => {
        if (!period) return
        axios_server.get(`/cms/stats/${period}`).then((res) => {
            return setData(res.data)
        })
    }, [period])

    if (!data) return null
    return <><table className='mod-statistics'>
        <thead>
            <tr>
                <th>Yrke</th>
                <th>Ackord</th>
            </tr>
        </thead>
        <tbody>
            {data.map(item => {
                if (!item) return null
                return <tr key={item.yrke}>
                    <td>{item.yrke}</td><td>{item.accord}</td>
                </tr>
            })}
        </tbody>
    </table></>
}