import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useState } from "react";

const H3 = ({item, index}) => {
    const [isExpanded, setExpanded] = useState(index === 1 ? true : false)
    //console.log(index)
    const toggleExpanded = () => {
        setExpanded(!isExpanded)
    }

    const lines = item.split('\n')
    const title = lines.splice(0, 1)[0].replace(/^### /, '')
    //console.log(title)
    const content = lines.join('\n')

    return (
        <>
            <h3 onClick={() => {toggleExpanded()}} style={{cursor: 'pointer'}} remarkPlugins={[remarkGfm]}>{title} {isExpanded ? '▲' : '▼'}</h3>
            {isExpanded && <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>}
            
        </>
    )
}

export const Help = ({ item, options }) => {
    const introtext = item?.introtext
    const split = introtext.split(/\n(?=##)/).map((item) => item.trim());
    //console.log(split)
    return (
        <>
        
        {
            split.map((item, index) => {
                if(item.startsWith("###"))
                    return <H3 item={item} index={index}/>
                return <ReactMarkdown remarkPlugins={[remarkGfm]}>{item}</ReactMarkdown>
                
            })
        }
        </>
    )
}