import React from 'react';


function StartPage() {
    return (
        <>
<h1>Välkommen till Måleriportalen.se</h1>
<p>Måleriportalen är branschens gemensamma forum för insamling av statistik samt en kanal för information som ska komma alla företag och anställda till del.</p>

<p>Målsättningen är att företagens totala administration ska underlättas, t ex är kopplingen till lärlingshanteringen. Målet är att varje företag endast ska behöva lämna uppgifter här oavsett vem som ska ha del av dem.</p>

<p>Ni har enligt kollektivavtal skyldighet att lämna löneuppgifter en gång i månaden.&nbsp;</p>

<h2>Lärlingsportalen - för arbetsgivare&nbsp;</h2>

<p>Alla företag som är anslutna till måleribranschens kollektivavtal ska administrera sina lärlingsärenden via Måleriportalen i Lärlingsportalen. I Lärlingsportalen ansöker företaget om att anställa en lärling, anmäla gesällprov och avsluta en lärlingsanställning. Blanketter som används i olika ärenden finns som webbformulär som är åtkomliga i Lärlingsportalen.</p>

<p>Lärlingens arbetade utbildningstimmar, som arbetsgivaren månadsvis registrerar i Måleriportalen, överförs automatiskt från Måleriportalen till lärlingens utbildningsbok i Lärlingsportalen 7 dagar efter det att timmarna registrerats i Måleriportalen.</p>

<p>Du öppnar Lärlingsportalen genom att logga in i Måleriportalen och därefter klicka på rubriken "Lärlingsportalen" i vänsterkolumnen.</p>

<p>På Måleribranschens Yrkesnämnds (MYN) hemsida (www.malare.nu) finns mer information om Lärlingsportalen. Där hittar du också lathundar för Lärlingsportalen.</p>

<h2>GDPR</h2>

<p>Alla personuppgifter som lämnas till Måleriportalen krypteras, Målareförbundet kan endast dekryptera dom personer som är aktiva medlemmar.</p>
        </>
    )
}

export default StartPage;