import React, { useMemo, useState } from 'react'
// import '../../Style/forms.css'
import './receiptlist.css'
import './datepicker_jqui.css'
import './print.css'
import classNames from 'classnames'
// import './webform2.0.css'
import { DebugComponent } from '../../Components/DebugComponent.js'
import { axios_server } from '../../Client/client.js'
import { useNavigate } from 'react-router-dom';

import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const PrintSingle2 = ({...props}) => {

    
    const data = props.props
    //console.log(data)

    return (

        <div class="webform-print-page">
        
            <div class="printdiv" id="printdiv">
                <div style={{'marginBottom': '48px', 'verticalAlign': 'middle'}}>
                    <span style={{'fontSize': '24px'}}>
                        Måleriportalen kvittens
                    </span>
                </div>
                <dl>
                    <dt>Företag</dt>
                    <dd>{data.CoName}</dd>
                    <dt>Uppgifterna lämnades</dt>
                    <dd>{data.SentDate}</dd>
                    <dt>Utbetalningsdatum</dt>
                    <dd>{data.PayDate}</dd>
                    <dt>Antal personer</dt>
                    <dd>{data.NumPersons}</dd>
                    <dt>Ersättningskod</dt>
                    <dd>{data.UpdateCode}</dd>
                    <dt>Kvittenskod</dt>
                    <dd>{data.ReceiptCode}</dd>
                </dl>
            </div>
        </div>
    )
}

const PrintYear = React.forwardRef((props, ref) => {

    /*

        array of objects

        [
            {
                CoName,
                NumPersons,
                OrgNo,
                PayDate,
                Processed,
                ReceiptCode,
                ReplacedBy,
                Replaces,
                SentDate,
                SentTime,
                UpdateCode
            }
        ]
    */

    return (
        <div ref={ref}>
            
            
            <div class="printdiv" id="printdiv">
                
                {
                    props.data ? props.data.map((item) => {
                        return <PrintSingle2 props={item}/>
                    }) : <></>
                }
                
            </div>
        </div>
    
    
    )
})

const PrintAll = React.forwardRef((props, ref) => {

    const my_list = []

    //console.log(props.data)
    if(props.data){
        for (const key in props.data) {
            if (props.data.hasOwnProperty(key)) {
                my_list.push(props.data[key])
            }
        }
    }
    

    return (
        <div ref={ref}>
            
            <div class="printdiv" id="printdiv">

                {
                    my_list.map((year) => {
                        return year.map((item) => {
                            return <PrintSingle2 props={item}/>
                        })
                    })
                }
                                
            </div>
        </div>
    
    
    )
})



const PrintSingle = React.forwardRef((props, ref) => {

    return (
        <div ref={ref}>
            
            <div class="printdiv" id="printdiv">
                <div style={{'marginBottom': '48px', 'verticalAlign': 'middle'}}>
                    <span style={{'fontSize': '24px'}}>
                        Måleriportalen kvittens
                    </span>
                </div>
                <dl>
                    <dt>Företag</dt>
                    <dd>{props.data.company}</dd>
                    <dt>Uppgifterna lämnades</dt>
                    <dd>{props.data.sentdate}</dd>
                    <dt>Utbetalningsdatum</dt>
                    <dd>{props.data.paydate}</dd>
                    <dt>Antal personer</dt>
                    <dd>{props.data.num_people}</dd>
                    <dt>Ersättningskod</dt>
                    <dd>{props.data.repalcement_code}</dd>
                    <dt>Kvittenskod</dt>
                    <dd>{props.data.receipt_code}</dd>
                </dl>
            </div>
        </div>
    
    
    )
})

export const Receiptlist = ({...items}) => {

    const receipt_code = items?.['receipt']
    //console.log(receipt_code)


    const [year, setYear] = useState(new Date().getFullYear())

    const handleChangedYear = (year) => {
        console.log(year)
        setYear(year)
    }

    // data is an object, keys are years, values are lists with receipts for those years
    const [data, setData] = React.useState([])
    const [query, setQuery] = React.useState({})
    const [hideReplaced, setHideReplaced] = React.useState(false)
    const [hideBad, setHideBad] = React.useState(false)

    React.useEffect(() => {
        Search(query);
    }, [query])
    function Search(query) {
        setQuery(query)
        const limit = query.limit || 5;
        const offset = (query.page - 1) * limit;
        const search = [
            `limit=${limit}`,
            `offset=${offset}`
        ];
        if (query.alias && !query.flash) search.push(`alias=${query.alias}`);
        axios_server.get(`/mp/reciepts?${search.join('&')}`).then((res) => {
            const data = {}
            //console.log("HEJ ELIS", res.data)
            res.data.records.forEach(kvittens => {

                // get the year of the receipt
                const fullYear = new mpDate(kvittens.PayDate).date.getFullYear()

                // put the receipt in an array under that year
                if (!data[fullYear]) data[fullYear] = []
                data[fullYear].push(kvittens)
            })
            setData(data);
        });
    }

    const componentRefYear = useRef();
    const handlePrintYear = useReactToPrint({
        content: () => componentRefYear.current,
    });

    const componentRefAll = useRef();
    const handlePrintAll = useReactToPrint({
        content: () => componentRefAll.current,
    });

    return <>
        <PrintYear ref={componentRefYear} data={data[year]}/>
        <PrintAll ref={componentRefAll} data={data}/>
        <h1>Kvittenser</h1>

        {/*
            <input 
                type="checkbox"
                checked={hideReplaced}
                onChange={() => {setHideReplaced(!hideReplaced)}}
            /> Dölj ersatta kvittenser
            <br/>
            <input 
                type="checkbox"
                checked={hideBad}
                onChange={() => {setHideBad(!hideBad)}}
            /> Dölj felaktiga kvittenser
        */}
        

        
        <div className="receiptlist-printbutton"><a onClick={handlePrintAll} className="receiptlist-printbutton-printall">Skriv ut alla</a></div>
        <div className="receiptlist-printbutton"><a onClick={handlePrintYear} className="receiptlist-printbutton-printyear">Skriv ut årets</a></div>
        <div style={{ clear: "both" }}></div>
        <ReciptYearSelector year={year} handleChangedYear={handleChangedYear} />
        <RecieptListItems year={year} data={data[year]} receipt_code={receipt_code} hideReplaced={hideReplaced} hideBad={hideBad}/>
        <DebugComponent values={data} />
    </>
}
export const RecieptListItems = ({ data, year, receipt_code, hideReplaced, hideBad }) => {
    return <div id="receiptlist"  >
        <div style={{ clear: "both" }} />
        {data?.map((kvittens, index) => (< Receipt receipt_code={receipt_code} key={`${kvittens.PayDate}-${kvittens.UpdateCode}-${index}`} recieipt={kvittens} year={year} hideReplaced={hideReplaced} hideBad={hideBad} />))}
    </div>
}
export const ReciptYearSelector = ({ year, handleChangedYear }) => {
    return <div className="receiptlist-year-wrapper">
        <input type="button" className="leftarrow" id="receiptlist-year-earlier" onClick={(e) => handleChangedYear(year - 1)} />
        <YearPicker year={year} handleChangedYear={handleChangedYear} />
        <input type="button" className="rightarrow" id="receiptlist-year-later" onClick={(e) => handleChangedYear(year + 1)} />
        <div style={{ clear: "both" }}></div>
    </div>
}
export const Receipt = ({...props}) => {

    

    const hide3 = (props.receipt_code === undefined || props.receipt_code.toUpperCase() !== props.recieipt?.ReceiptCode?.toUpperCase()) ? true : false

    const [hide, setHide] = useState(hide3)
    const toggleHide = e => {
        setHide(!hide)
    }

    const red = (props.recieipt.status !== 'G' && props.recieipt.status !== 'T' && props.recieipt.status !== 'E') || props.recieipt.HasKvittensRad !== 0 || props.recieipt.antalFel !== 0
    const gray = props.recieipt.ReplacedBy
    if(props.hideReplaced){
        if(gray)
            return <></>
    }
    if(props.hideBad) {
        if(red)
            return <></>
    }
    
    
    return <>
        <RececiptHeader {...props} toggleHide={toggleHide} hide={hide} />
        <RececiptBody {...props} hide={hide} />
    </>
}
/*export const RececiptHeader = ({ recieipt, toggleHide, hide }) => {

    const mpDate_sent = new mpDate(recieipt.SentDate, recieipt.SentTime)

    return <div className={ recieipt.ReplacedBy ? "receiptlist-header-gray" : mpDate_sent.old ? "receiptlist-header" : "receiptlist-header"} >
        <input type="hidden" value="Visa" />
        <input type="button" className={ recieipt.ReplacedBy ? "normal-button-gray" : mpDate_sent.old ? "normal-button-blue" : "normal-button-blue"} value={hide ? "Visa" : "Dölj"} onClick={(e) => toggleHide()} />
        {new mpDate(recieipt.PayDate).present}
        <div style={{ clear: "both" }}></div>
    </div>
}*/

export const RececiptHeader = ({ recieipt, toggleHide, hide }) => {

    //const mpDate_sent = new mpDate(recieipt.SentDate, recieipt.SentTime)
    //const red = (recieipt.status !== 'G' && recieipt.status !== 'T' && recieipt.status !== 'E') || recieipt.HasKvittensRad !== 0 || recieipt.antalFel !== 0
    //const gray = recieipt.ReplacedBy
    const red = false
    const gray = false

    return <div className={red ? "receiptlist-header-darker" : (gray ? "receiptlist-header-gray":"receiptlist-header")} >
        <input type="hidden" value="Visa" />
        <input type="button" className={red ? "normal-button-darker" : (gray ? "normal-button-gray" : "normal-button-blue")} value={ hide ? "Visa" : "Visa"} onClick={(e) => toggleHide()} />
        {new mpDate(recieipt.PayDate).present}
        <div style={{ clear: "both" }}></div>
    </div>
}

export const RececiptBody = ({ recieipt, hide }) => {

    const navigate = useNavigate();

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const company = new OrgNo(recieipt.OrgNo).present + recieipt.CoName
    
    const mpDate_payDate = new mpDate(recieipt.PayDate).present
    const update_code = recieipt.UpdateCode ? new UpdateCode(recieipt.UpdateCode).present : ''
    const receipt_code = recieipt.ReceiptCode ?  new Kvittenskod(recieipt.ReceiptCode).present : ''
    const replaced_by = recieipt.ReplacedBy ? new Kvittenskod(recieipt.ReplacedBy).present : ''
    const replaces = recieipt.Replaces ? new Kvittenskod(recieipt.Replaces).present : ''

    let sentdate;
    let old;
    try {
        const mpDate_sent = new mpDate(recieipt.SentDate, recieipt.SentTime)
        sentdate = mpDate_sent.present
        old = mpDate_sent.old
    } catch (error) {
        sentdate = ''
        old = ''
    }

    const printData = {
        company,
        sentdate,
        paydate: mpDate_payDate,
        num_people: recieipt.NumPersons,
        repalcement_code: update_code,
        receipt_code: receipt_code
    }

    if (hide) return null
    return (
        <>
            <PrintSingle ref={componentRef} data={printData}/>
            <div className="receiptlist-body" style={{ paddingTop: "0px", borderTop: "none", paddingBottom: "0px", borderBottom: "none", overflow: "hidden", visibility: "visible", zoom: "1", opacity: "1" }}>
                <div className="receiptlist-body-inner">
                    <div className="receiptlist-printbutton"><a onClick={handlePrint} className="receiptlist-printbutton-printone">Skriv ut</a></div>
                    <div className="receiptlist-body-inner-printable">
                        <dl>
                        <dt>Företag</dt>
                            <dd>{company}</dd>
                            <dt>Uppgifterna lämnades</dt>
                            <dd>{sentdate}</dd>
                            <dt>Utbetalningsdatum</dt>
                            <dd>{mpDate_payDate}</dd>
                            <dt>Antal personer</dt>
                            <dd>{recieipt.NumPersons}</dd>
                            <dt>Ersättningskod</dt>
                            <dd>{update_code}</dd>
                            <dt>Kvittenskod</dt>
                            <dd>{receipt_code}</dd>
                            {recieipt.ReplacedBy && <><dt>Ersatt med</dt>
                                <dd>{replaced_by}</dd></>}
                            {recieipt.Replaces && <><dt>Ersätter</dt>
                                <dd>{replaces}</dd></>}
                        </dl>
                    </div>
                    <div className="receiptlist-replace">
                    <span> {recieipt.ReplacedBy ? 'Löneuppgiften är redan ersatt' : old ? 'Tiden för att ersätta uppgifterna har gått ut' : ''} </span><button disabled={(recieipt.ReplacedBy || old) ? true : false} onClick={() => navigate('/index.php/lamna-loneuppgifter', { state: {title: 'Lämna löneuppgifter', link: `index.php?option=com_monetform&view=uploadform&update_code=${recieipt.UpdateCode}`} })}>Ersätt</button>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </div>
        </>
    )
}
export class OrgNo {
    constructor(orgno) {
        this._orgno = orgno
    }
    get present() {
        return chunkSubstr(this._orgno.toUpperCase(), 6).join('-')
    }
}
export class Kvittenskod {
    constructor(kod) {
        this._kod = kod
    }
    get present() {
        return chunkSubstr(this._kod.toUpperCase(), 4).join(' ')
    }
}
export class UpdateCode {
    constructor(kod) {
        this._kod = kod
    }
    get present() {
        return chunkSubstr(this._kod.toUpperCase(), 4).join('-')
    }
}
function chunkSubstr(str, size) {
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array(numChunks)

    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
        chunks[i] = str.substr(o, size)
    }

    return chunks
}
export class mpDate {
    _dateSet = false
    _timeSet = false
    _date = undefined
    _time = undefined
    constructor(date, time) {
        if (date) {
            if (typeof date === 'number') {
                this._date = new Date(date)
            }
            else if (date instanceof Date) {
                this._date = date
            } else if (date.indexOf('Z') > -1) {
                this._date = new Date(new Date(Date.parse(date)))
            } else {
                this._date = new Date(date.substring(0, 4), date.substring(4, 6) - 1, date.substring(6, 8))
            }
            this._dateSet = true
        }
        if (time) {
            this._time = new Date(0, 0, 0, time.substring(0, 2), time.substring(2, 4), time.substring(4, 6))
            this._timeSet = true
        }

    }
    get present() {
        return `${this._date.toLocaleDateString('sv-se')}${(this._timeSet) ? ' ' + this._time.toLocaleTimeString('sv-se') : ''}`
    }
    get date() {
        return this._date
    }
    get old() {

        const myDate = new Date(Date.parse(this.present)); // convert string to Date object

        const now = new Date(); // get the current date and time
        const sevenDaysAgo = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000)); // calculate date and time 7 days ago
        
        return (myDate < sevenDaysAgo)
    }
}
const YearPicker = ({ year, handleChangedYear }) => {
    const [showYearSelector, setShowYearSelector] = useState(false)

    const startYear = (Math.floor(year / 10) * 10)
    const years = []
    const today = new Date().getFullYear

    for (let index = startYear; index < (startYear + 20); index++) {
        years.push(index)
    }

    return <>
        <input id="receiptlist-year" type="text" value={year} onChange={e => handleChangedYear(parseInt(e.target.value))} onFocus={e => setShowYearSelector(true)} onBlur={e => setShowYearSelector(false)} />
        {showYearSelector && <div className="datepicker_jqui column_1" style={{ left: "250px", top: "110px", zoom: 1 }}>
            <div className="header">
                <div className="title">
                    <div className="titleText" id="pickertitle-l9znlimt" aria-live="assertive" aria-atomic="true">
                        <div className="column column_1">{startYear}-{startYear + 19}</div>
                    </div>
                </div>
                <div role="button" className="closeButton">x</div>
                <div className="previous" style={{ display: "block" }}>«</div>
                <div className="next" style={{ display: "block" }}>»</div>
            </div>
            <div className="body">
                <div className="slider" style={{ position: "absolute", top: "0px", left: "0px", width: "370px", height: "131px" }}>
                    <div style={{ position: "absolute", top: "0px", left: "0px", width: "185px", height: "131px", visibility: "visible", zoom: 1, opacity: 1 }}>
                        <div className="column column_1">
                            <div className="years">
                                {years.map(index => {
                                    const css = classNames(
                                        'year',
                                        { selected: (index === year) },
                                        { today: (index === today) }

                                    )
                                    return <div key={index} className={css} onClick={e => {
                                        handleChangedYear(index)
                                    }}>{index}</div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >}
    </>
}