import React, { useRef, useState } from 'react'
import '../../Style/forms.css'
import './style.css'
import { axios_server } from '../../Client/client.js'
import { useParams } from 'react-router-dom'

export const Larlingsportalen = ({ params }) => {
    
    const openLarlingsportalen = async () => {

        
        await axios_server.get('/mp/mpa002/getUserTicket')
        .then(response => {
            const ticket = response.data.ticket
            const url = "https://larlingsportalen.softadmin.se/admin/customization/pages/companylogin.aspx" + "?ticket=" + ticket
            window.open(url, '_blank')
        })
        .catch(error => {
            alert(error)
        })
    
    }

    return <>
        <h1>Lärlingsportalen</h1>

        <div class="apprenticelink-link">
        <form action="" method="get" class="apprenticelink-link">
            <div class="apprenticelink-info">
            Klicka på knappen nedan för att öppna Lärlingsportalen.
            </div>{/*<div style="clear: both;"></div>*/}
            <fieldset>
            <legend>Till Lärlingsportalen</legend>
            <div class="apprenticelink-button-container">
            <button type='button' onClick={() => {openLarlingsportalen()}}>Öppna</button>
            </div>
            </fieldset>
        </form>
        </div>
    </>
}
