import { useState, useEffect, useRef, useContext } from 'react'
import { axios_server } from '../../Client/client.js'
import './style.css'
import '../../Style/forms.css'
import { ValidateEmail } from '../../Utils/validators.js'
import { Form, Field } from 'react-final-form'
import { PopupMessageContext } from '../../Client/PopupMessageContect.js'
export const UserEdit = (props) => {
    const [data, setData] = useState(null)
    const [extra, setExtra] = useState([])
    const [query, setQuery] = useState({})
    const [suggestedName, setSuggestedName] = useState('')

    useEffect(() => {
        const fetchData = async () => {
            await Search(query);
        }
        fetchData()  // make sure to catch any error
            .catch('Error Fetching data');
    }, [query])
    async function Search(query) {
        setQuery(query)
        const limit = query.limit || 5;
        const offset = (query.page - 1) * limit;
        const search = [
            `limit=${limit}`,
            `offset=${offset}`
        ];
        if (query.alias && !query.flash) search.push(`alias=${query.alias}`);
        await axios_server.get(`/mp/accountlist?${search.join('&')}`)
            .then((res) => {
                setData(res.data);
            })
            .catch(error => {
                console.log(error.message)
            })
        await axios_server.get(`/mp/mpa012/epostlist?${search.join('&')}`)
            .then((res) => {
                setExtra(res.data);
            })
            .catch(error => {
                console.log(error.message)
            })
        await axios_server.get(`/mp/nextAccountNameSuggestion`)
            .then(res => {
                setSuggestedName(res.data.name)
                console.log(res.data.name)
            })
            .catch(error => {
                console.log(error.message)
            })
    }
    const Add = (email) => {
        if (ValidateEmail(email)) {
            alert(ValidateEmail(email))
            return
        }
        if (extra.records.filter(item => (item.epost === email && item.type === 'noUser')).length > 0) {
            alert(`${email} finns redan i listan.`)
            return
        }
        let data = {
            epost: email
        }
        axios_server.post('/mp/mpa012/epostadd', data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setExtra(res.data)
        }).catch(err => {
            alert(JSON.stringify(err, null, 2))
        })
    }
    const Del = (emails) => {
        if (emails.length === 0) return
        let data = emails
        axios_server.delete('/mp/mpa012/epostsubepost', { data }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            setExtra(res.data)
        }).catch(err => {
            alert(JSON.stringify(err, null, 2))
        })
    }

    return <>
        <h1>Lägg till konto</h1>

        <div className="fkaddaccount-user">
            <AddAccountForm name={suggestedName} />
            <AddEmailReciever extra={extra} Add={Add} Del={Del} />
            <AccountList users={data} />
        </div>
    </>
}



export const AddAccountForm = (props) => {

    const initialValues = {
        name: props.name
    }

    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)

    const onSubmit = async (values) => {

        // values.email
        // values.name

        const url = "/mp/mpa002/add_account"
        const data = values
        const config = {}

        axios_server.post(url, data, config)
            .then(response => {
                console.log(response.data)
                setPopupMessage({ info: response.data.message, error: '' })
            })
            .catch(error => {
                if (error.response.status === 403) {
                    setPopupMessage({ info: '', error: '403 - du är inte autentiserad' })
                } else if (error?.response?.data?.message) {
                    setPopupMessage({ info: '', error: error.response.data.message })
                } else {
                    setPopupMessage({ info: '', error: "Något gick fel, försök igen senare." })
                }

                console.log(error.response.status)
            })

    }

    return (
        <>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form action="" method="post" className="fkaddaccount-user" id="formAddAccount" onSubmit={handleSubmit}>
                        <input type="hidden" name="option" value="com_fkaddaccount" />
                        <input type="hidden" name="add" value="1" />
                        <fieldset>
                            <legend>Lägg till nytt konto till företaget</legend>
                            <div className="fkaddaccount-user-row">
                                <div className="fkaddaccount-user-title"></div>
                                <Field
                                    name="email"
                                    component="input"
                                    type="text"
                                    placeholder=""
                                />

                                <div className="fkaddaccount-user-info">Ange den e-postadress dit de nya kontouppgifterna ska skickas</div>
                            </div>
                            <div className="fkaddaccount-user-title">&nbsp;</div>
                            <div className="fkaddaccount-user-row">
                                <div className="fkaddaccount-user-title"></div>
                                <Field
                                    name="name"
                                    component="input"
                                    type="text"
                                    placeholder=""
                                />
                                <div className="fkaddaccount-user-info">
                                    Använd det förifyllda användarnamnet till det nya kontot eller ange ett nytt. Användarnamnet måste bestå av minst fyra bokstäver och får inte innehålla några siffror bortsett från organisationsnumret.
                                </div>
                            </div>
                            <div className="fkaddaccount-user-row">
                                <div className="fkaddaccount-user-title">&nbsp;</div>
                                <input type="submit" id="submit-add-account" value="Skapa" />
                                <img src="https://www.maleriportalen.se/components/com_fkaddaccount/images/spinner.gif" alt="" id="loading-spinner" style={{ left: "283px", top: "24px" }} />
                            </div>
                        </fieldset>
                    </form>
                )}
            />
        </>
    )
}

export const AddEmailReciever = ({ extra, Add, Del }) => {
    /**
     * @param  {string} email email to add to subscribers
     */
    const [list, setList] = useState([])

    const listAdd = (email) => {
        list.push(email)
        setList(list)
    }
    const listSub = (email) => {
        const listDel = list.filter(word => word !== email)
        setList(listDel)
    }

    return <div action="" method="post" className="fkaddaccount-user">
        <input type="hidden" name="option" value="com_fkuserextra" />
        <fieldset>
            <legend>Lägg till e-postadress</legend>
            <AddEmail Add={Add} />
            <div className="fkaddaccount-user-row">
                <div className="fkaddaccount-user-title">&nbsp;</div>
                <div className="fkaddaccount-user-info"><b>E-postadresser som används vid utskick av påminnelser:</b><br /></div>
                <div className="fkaddaccount-user-info" id="g_g_extra">
                    {extra?.records?.filter(item => item.type === 'noUser').map(item => {
                        return <p key={item.epost} style={{ maxHeight: "15px" }}><input name={item.epost} onClick={e => {
                            e.target.checked ? listAdd(item.epost) : listSub(item.epost)
                        }} type="checkbox" />&nbsp;{item.epost}</p>
                    })}
                </div>
            </div>
            <div className="fkaddaccount-user-row">
                <div className="fkaddaccount-user-title">&nbsp;</div>
                <div className="fkaddaccount-user-info">Markera ovan de e-postadresser ni vill ta bort.</div>
                <div className="fkaddaccount-user-title">&nbsp;</div>
                <input type="submit" onClick={e => {
                    Del(list)
                    setList([])
                }} id="undoButton" value="Ta bort" readOnly="" disabled="" />
            </div>
        </fieldset>
    </div>
}
const AddEmail = ({ Add }) => {
    const AddUserEpost = useRef(null)
    const [errors, setErrors] = useState({ e_e_epost: null })

    return <>
        <div className="fkaddaccount-user-row">
            <div className="fkaddaccount-user-title">&nbsp;</div>
            <input ref={AddUserEpost} type="text" id="e_e_epost" onBlur={e => { setErrors({ ...errors, e_e_epost: ValidateEmail(AddUserEpost.current.value) }) }} />
            {errors.e_e_epost && <div className="fkaddaccount-user-error">{errors.e_e_epost}</div>}
            <div className="fkaddaccount-user-info">Önskas ytterligare e-postadresser vid utskick av påminnelser än de som listas i rutan Företagets konton och e-postadresser lägg till här.</div>
        </div>
        <div className="fkaddaccount-user-row">
            <div className="fkaddaccount-user-title">&nbsp;</div>
            <input type="submit" onClick={e => { Add(AddUserEpost.current.value) }} value="Lägg till" style={{ marginRight: "10px" }} />
        </div>
    </>
}

export const AccountList = ({ users }) => {
    if (users === null) return null

    return (<div action="" method="post" className="fkaddaccount-user">
        <input type="hidden" name="option" value="com_fkuserextra" />
        <fieldset>
            <legend>Företagets konton</legend>
            <table className="fkaddaccount-user-user" style={{ marginLeft: "20px", fontSize: "12px", width: "90%" }}>
                <thead>
                    <tr className="fkaddaccount-user-row">
                        <th className="fkaddaccount-user-title">Användarnamn</th>
                        <th className="fkaddaccount-user-title">Aktiveringskod</th>
                        <th className="fkaddaccount-user-title">E-post</th>
                        <th className="fkaddaccount-user-title">Aktiverad</th>
                    </tr>
                </thead>
                <tbody>
                    {users?.records?.map(item => <AccountListRow key={item.Username} item={item} />)}
                </tbody>
            </table>
        </fieldset>
    </div>
    )
}

const AccountListRow = ({ item }) => {
    return <tr className="fkaddaccount-user-row">
        <td className="fkaddaccount-user-info" >{item.Username}</td>
        <td className="fkaddaccount-user-info" >{item.ActivationCode}</td>
        <td className="fkaddaccount-user-info" >{item.Epost}</td>
        <td className="fkaddaccount-user-info" >{(item.Status === 'A') ? 'Ja' : 'Nej'}</td>
    </tr>
}
