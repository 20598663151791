

export const UploadformDisabled = ({...items}) => {
    return (
        <>
            <h1>Information om arbete i Måleriportalen</h1>
            <p>Vi ser just nu över viss funktionalitet i Måleriportalen varför inrapportering av löner inte kan göras. Anställning av lärlingar fungerar som vanligt.</p>
            <p>Vi jobbar fortfarande på för att vi så snabbt som möjligt ska kunna öppna lönerapporteringen i Måleriportalen igen. Vi beklagar att det drar ut i tid. Parallellt arbetar vi med en alternativ lösning för hantering av lärlingar tills dess att rapporteringen fungerar som vanligt.</p>
            <p>Vi återkommer med information via Måleriföretagen och Måleriportalen när inrapportering kan ske igen.</p>
            <p>Vid frågor kontakta supporten.</p>
        </>
    )
}