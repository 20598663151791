import React, { useEffect, useState } from 'react'
import { axios_server } from '../../../Client/client.js'
import './monetform.css'
import '../../../Style/system.css'
import warning from '../../../images/monet-warning.png'
import { mpDate } from '../../Receiptlist/Receiptlist.js'
import classNames from 'classnames'
import { Field, Form } from "react-final-form"
import { DebugComponent } from '../../../Components/DebugComponent.js'
import { Message } from '../../../Components/Message/Message.js'


export const PauseForm = (props) => {
    const [paused, setPaused] = useState(null)
    const [message, setMessage] = useState(null)
    useEffect(() => {
        if (!paused) {
            axios_server.get(`/mp/pausestatus`).then((res) => {
                setPaused(res?.data);
            });
        }
    }, [paused])
    if (!paused) return null
    const initialValues = {
        StartDate: new mpDate(paused.isPaused ? paused.isPausedFromDate : new Date().setDate(25)).present
    }
    const required = value => (value ? undefined : 'Obligatorisk')

    const validate = (values) => {
        const errors = { password: [], email: [] }
        // if (values.password[0].length < 12) errors.password[0] = "Lösenordet för kort, minst 12 tecken."
        // if (values.password[0] !== values.password[1]) errors.password[0] = "Lösenordern skiljer."
        // const letters = values.password[0].split('')
        // const unique = new Set(letters)
        // unique.forEach(c => {
        //     if (allowedPasswordChars.indexOf(c) === -1) errors.password[0] = "Lösenordet innehåller ogiltigt tecken."
        // });
        return errors
    }
    const onSubmit = async (values) => {
        const config = {
            method: 'post',
            url: '/mp/pause',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values
        };
        try {
            const res = await axios_server(config)
            setMessage({ result: 'ok', message: 'Du har anmält att företaget saknar anställda' })
            if (res.data.result === 'ok') setPaused(null)
        } catch (error) {
            console.log(error)
            if (error.response.status === 400) setMessage(error.response.data)
        }

        // window.alert(JSON.stringify(res, 0, 2))

        //alert("submit!")
    }

    const years = []

    for (let y = 2022; y >= 2012; y--) {
        years.push(y)

    }
    return <>
        <Message message={message} />

        <div className="monet-pause-info">
            <p>Om företaget inte har några anställda inom avtalsområdet kan detta anmälas här för att undvika att påminnelsebrev skickas till er.
                <br />När löneuppgifter inkommer plockas noteringen bort och påminnelsebrev kommer åter skickas vid sen inlämning.</p>

            <p>Välj i kalendern från vilken månad noteringen ska gälla.</p>

            <p>Tänk på att om ni anställer personal är ni skyldiga att börja redovisa här på Måleriportalen.</p>

        </div><div style={{ clear: "both" }}></div>

        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            mutators={{
                setStartDate: ([value], state, { changeValue }) => {
                    // §(value)
                    changeValue(state, 'StartDate', () => value)
                }
            }}
            render={({ handleSubmit, values, form }) => (
                < form onSubmit={handleSubmit} >
                    <input type="hidden" name="pause" value="pause" />
                    {paused.isPaused && <div className="monet-pause-status-info">
                        <img src={warning} alt='' /> Företaget har anmält att det saknas anställda sedan {new mpDate(paused.isPausedFromDate).present}
                    </div>}
                    <div className="monet-pause-form">
                        <div>
                            <Field name="StartDate" validate={required}>{props => (
                                <>
                                    <label htmlFor={props.input.name}>Startdatum</label><br />
                                    <input type="text" id="monet-pause-start" {...props.input} />
                                </>)}</Field> <input type="submit" value={(paused.isPaused) ? " Ändra" : "Anmäl"} onClick={e => handleSubmit(e)} />
                            {(paused.isPaused) && <span className="monet-pause-info">OBS! Du ändrar en befintlig notering.</span>}
                        </div>
                    </div>
                    <DebugComponent values={values} />
                    {years.map((year, index) => <YearCal year={year} index={index} key={year} pausedFrom={new Date(paused.isPausedFromDate)} handleSelect={(y, m) => {
                        const d = new mpDate(new Date(y, m, 25)).present
                        form.mutators.setStartDate(d)
                    }} />)}
                </form>)}
        />
    </>
}

const YearCal = ({ year, index, pausedFrom, handleSelect }) => {

    const mainCls = classNames(
        'monet-pause-calendar-year',
        { 'monet-pause-calendar-year-right': index % 2 }
    )
    const selected = (y, m) => {
        return (pausedFrom.getFullYear() === y && pausedFrom.getMonth() === m)
    }
    const month = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    return <div className={mainCls}>
        < div className="monet-pause-calendar-year-heading" > <h3 className="monet-pause-calendar-year">{year}</h3></div >
        {month.map(m => <MonthCal key={`${year}-${m}`} year={year} month={m} selected={selected(year, m)} handleSelect={handleSelect} />)}
    </div >
}
const MonthCal = ({ year, month, selected, handleSelect }) => {
    const [highlight, setHighlight] = useState(false)
    const monthCls = classNames(
        'monet-pause-calendar-month',
        `monet-pause-calendar-month-${month + 1}`,
        { 'monet-pause-calendar-month-highlighted': highlight },
        { 'monet-highlighted-above': highlight },
        { 'monet-highlighted-right': highlight },
        { 'monet-highlighted-below': highlight },
        { 'monet-highlighted-left': highlight }
    )
    const style = {}
    if (selected) style.background = 'grey'
    return <div className={monthCls} style={style} onClick={e => handleSelect(year, month)} onMouseOver={e => {
        // console.log(`MouseOver ${year}-${month}`);
        setHighlight(true)
    }} onMouseLeave={e => setHighlight(false)}>
        {/* <input type="hidden" className="fulldate" value="<?php echo sprintf('%d-%02d-25', $year->year, $month->month); ?>" /> */}
        <h4 className="monet-pause-calendar-month">{new Date(year, month, 25).toLocaleString('default', { month: 'long' })}</h4>
        <div className="monet-pause-calendar-month-highlight-top"></div>
        <div className="monet-pause-calendar-month-highlight-right"></div>
        <div className="monet-pause-calendar-month-highlight-bottom"></div>
        <div className="monet-pause-calendar-month-highlight-left"></div>
    </div>
}
