import React from 'react'
import { axios_server } from '../../Client/client.js'
import '../../Style/style.css'
import News, { NewsItem } from '../../Components/News/News.js'
import { Statistik } from '../../Components/Statistik/Statistik.js'
import { Help } from '../../Components/Help/Help.js'
export const Content = ({ view, id, location, ...props }) => {
    const [data, setData] = React.useState(null)
    const PageOptions = JSON.parse(location?.state?.params || '{"layout_type":"blog","show_category_title":"","show_description":"","show_description_image":"","maxLevel":"","show_empty_categories":"","show_no_articles":"","show_subcat_desc":"","show_cat_num_articles":"","page_subheading":"","num_leading_articles":"5","num_intro_articles":"0","num_columns":"0","num_links":"0","multi_column_order":"","show_subcategory_content":"","orderby_pri":"","orderby_sec":"rdate","order_date":"published","show_pagination":"","show_pagination_results":"","show_title":"1","link_titles":"","show_intro":"","show_category":"","link_category":"","show_parent_category":"","link_parent_category":"","show_author":"","link_author":"","show_create_date":"","show_modify_date":"","show_publish_date":"1","show_item_navigation":"","show_vote":"","show_readmore":"1","show_readmore_title":"","show_icons":"","show_print_icon":"","show_email_icon":"","show_hits":"","show_noauth":"","show_feed_link":"","feed_summary":"","menu-anchor_title":"","menu-anchor_css":"","menu_image":"","menu_text":1,"page_title":"","show_page_heading":1,"page_heading":"","pageclass_sfx":"","menu-meta_description":"","menu-meta_keywords":"","robots":"","secure":0}')
    // const [query, setQuery] = React.useState({ id: params.get('id'), page: 1, limit: props.limit || 5, offset: 0 })
    let params = new URLSearchParams(location.search);
    // const id = params.get('id')

    React.useEffect(() => {
        const query = { id: id, page: 1, limit: props.limit || 5, offset: 0 }
        if (view === 'article') Search(query);
    }, [id, props.limit, view])

    function Search(query) {
        const search = Object.keys(query).map(key => (`${key}=${query[key]}`));
        axios_server.get(`/cms/content?${search.join('&')}`).then((res) => {
            setData(res.data);
        });
    }
    // return <>
    //     {data?.recordset?.map((item, index) => {
    //         return <div key={item.id}>
    //             <h1 key={index}>{(locationStateParams) ? locationStateParams.page_heading || locationStateParams.page_title : item.title}</h1>
    //             <ReactMarkdown remarkPlugins={[remarkGfm]}>{item.introtext}</ReactMarkdown>
    //         </div>
    //     })}
    //     <DebugComponent values={data} />
    // </>
    return <>
        {(!!PageOptions.show_page_heading) && <h1>{location.state.title}</h1>}
        {Body()}
    </>

    function Body() {
        switch (view) {
            case 'category':
                return <News {...props} location={location} options={PageOptions} />
            case 'article':
                const dataitem = data?.recordset[0]
                if (dataitem?.alias === "hjalp") return <Help item={dataitem} />
                if (dataitem?.introtext?.indexOf('{loadmodule') > -1) return <Statistik />
                return <NewsItem item={dataitem} options={PageOptions} />
            default:
                return <>Unhandled content view={params.get('view')}</>
        }
    }
}
