import { Field, Form } from "react-final-form"
import { axios_server } from "../../Client/client.js"
import { FormInputField } from "../../Components/FormInputField.js"
import './style.css'
import '../../Style/forms.css'
import { ValidateEmail } from "../../Utils/validators.js"
import ReactMarkdown from 'react-markdown'

import React, { useContext } from 'react'
import { PopupMessageContext } from "../../Client/PopupMessageContect.js"

export const ProfileSettings = (props) => {
    return <>
        <div id="system-message-container">
        </div>
        <div id="page-content-container">
            <h1>Ändra användarnamn eller lösenord</h1>
            <div className="fkuseredit-user">
                <ChangeUserName />

                <ChangePassword />

                <ChangeEmail />
            </div>
        </div>
    </>
}
const required = value => (value ? undefined : 'Obligatorisk')
const ChangeEmail = () => {
    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)
    const initialValues = { email: ['', ''] }
    const validate = (values) => {
        const errors = { email: [] }
        if (ValidateEmail(values.email[0])) errors.email[0] = ValidateEmail(values.email[0])
        if (values.email[0] !== values.email[1]) errors.email[0] = "Epostadresserna skiljer."
        return errors
    }

    const onSubmit = async (values) => {
        alert(JSON.stringify(values.email[0]))

        const config = {
            method: 'post',
            url: '/mp/mpa002/changeEmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {email: values.email[0]}
        };

        try {
            const res = await axios_server(config)
            setPopupMessage({info: 'Eposten har ändrats', error: ''})
        } catch (error) {
            setPopupMessage({info: '', error: error.message})
        }
    }
    return <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (<form onSubmit={handleSubmit} className="fkuseredit-user">
            <input type="hidden" name="option" value="com_fkuseredit" />
            <fieldset>
                <legend>Ny e-postadress</legend>
                <Field name="email.0" validate={required}>{props => (<FormInputField baseClass="fkuseredit-user" title="" type="text" info={'Ange ny e-postadress'}  {...props} />)}</Field>
                <Field name="email.1" validate={required}>{props => (<FormInputField baseClass="fkuseredit-user" title={null} info="Upprepa" type="text"  {...props} />)}</Field>

                <div className="fkuseredit-user-row">
                    <div className="fkuseredit-user-title">&nbsp;</div>
                    <input type="submit" value="Ändra" />
                </div>
            </fieldset>
        </form>)
        } />
}
const ChangePassword = () => {
    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)
    const allowedPasswordChars = 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z Å Ä Ö a b c d e f g h i j k l m n o p q r s t u v w x y z å ä ö 0 1 2 3 4 5 6 7 8 9 . , % $ # @ ! & ?'.split(' ')
    const NewPasswordInfo = <ReactMarkdown>{`Ange ditt önskade lösenord. \n
&nbsp;\n
Lösenordet måste bestå av minst 12 tecken och kan bestå av _bokstäver_, _siffror_ och _specialtecken( .,%$#@!&? )_. Exempel: Gs.g!FkN?kÖ2 \n
&nbsp;\n
**Godkända tecken:** ${allowedPasswordChars.join(' ')}`}</ReactMarkdown>
    const initialValues = { password: ['', ''] }
    const validate = (values) => {
        const errors = { password: [] }
        if (values.password[0].length < 12) errors.password[0] = "Lösenordet för kort, minst 12 tecken."
        if (values.password[0] !== values.password[1]) errors.password[0] = "Lösenordern skiljer."
        const letters = values.password[0].split('')
        const unique = new Set(letters)
        unique.forEach(c => {
            if (allowedPasswordChars.indexOf(c) === -1) errors.password[0] = "Lösenordet innehåller ogiltigt tecken."
        });
        return errors
    }

    const onSubmit = async (values) => {
        const config = {
            method: 'put',
            url: '/mp/changePassword',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values
        };

        
        try {
            const res = await axios_server(config)
            setPopupMessage({info: 'Lösenord har ändrats', error: ''})
        } catch (error) {
            setPopupMessage({info: '', error: error.message})
        }
        
    }
    return <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, values }) => (<form onSubmit={handleSubmit} className="fkuseredit-user">
            <input type="hidden" name="option" value="com_fkuseredit" />
            <fieldset>
                <legend>Nytt lösenord</legend>
                <Field name="password.0" validate={required}>{props => (<FormInputField baseClass="fkuseredit-user" title="" type="password" info={null}  {...props} />)}</Field>

                <div className="fkuseredit-user-info">
                    {NewPasswordInfo}
                </div>
                <Field name="password.1" validate={required}>{props => (<FormInputField baseClass="fkuseredit-user" title="" type="password" info='Upprepa' {...props} />)}</Field>

                <div className="fkuseredit-user-row">
                    <div className="fkuseredit-user-title">&nbsp;</div>
                    <input type="submit" value="Ändra" />
                </div>
            </fieldset>
        </form>)} />
}

const ChangeUserName = () => {
    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)
    const allowedUserNameChars = 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z Å Ä Ö a b c d e f g h i j k l m n o p q r s t u v w x y z å ä ö 0 1 2 3 4 5 6 7 8 9'.split(' ')
    const initialValues = { username: ['', ''] }
    const validate = (values) => {
        const errors = { username: [] }
        if (values.username[0].length < 4) errors.username[0] = "Användarnamnet är för kort, minst 4 tecken."
        if (values.username[0] !== values.username[1]) errors.username[0] = "Användarnamnet skiljer."
        const letters = values.username[0].split('')
        const unique = new Set(letters)
        unique.forEach(c => {
            if (allowedUserNameChars.indexOf(c) === -1) errors.username[0] = "Användarnamnet innehåller ogiltigt tecken."
        });
        return errors
    }

    const onSubmit = async (values) => {
        const config = {
            method: 'put',
            url: '/mp/changeUserName',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values
        };

        
        
        try {
            const res = await axios_server(config)
            setPopupMessage({info: 'Användarnamnet har ändrats', error: ''})
        } catch (error) {
            setPopupMessage({info: '', error: error.message})
        }
    }
    return <Form
        initialValues={initialValues}
        onSubmit={onSubmit}
        validate={validate}
        render={({handleSubmit, values }) => (
            <form onSubmit={handleSubmit} className="fkuseredit-user">
                <fieldset>
                    <legend>Nytt användarnamn</legend>
                    <div className="fkuseredit-user-row">
                        
                        <div className="fkuseredit-user-title">&nbsp;</div>
                        <Field name="username.0" validate={required}>{props => (<FormInputField baseClass="fkuseredit-user" title="" type="text" info={null}  {...props} />)}</Field>
                        
                        <div className="fkuseredit-user-info">Ange nytt användarnamn. Användarnamnet måste bestå av minst fyra tecken.</div>
                        <Field name="username.1" validate={required}>{props => (<FormInputField baseClass="fkuseredit-user" title="" type="text" info='Upprepa' {...props} />)}</Field>
                        
                    </div>

                    <div className="fkuseredit-user-row">
                        <div className="fkuseredit-user-title">&nbsp;</div>
                        <input type="submit" value="Ändra" />
                    </div>
                </fieldset>
            </form>
        )}
    />
}

