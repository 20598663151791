import React, { useRef, useState, useContext } from 'react'
import '../../Style/forms.css'
import './style.css'
import { axios_server } from '../../Client/client.js'
import { useParams } from 'react-router-dom'
import { PopupMessageContext } from '../../Client/PopupMessageContect.js'

export const Recover = ({ params }) => {
    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)
    const rc = params.get('rc')

    const [view, setView] = useState((rc) ? 'rc' : 'form')
    const username = useRef(null)
    const orgno = useRef(null)
    const pwd1 = useRef(null)
    const pwd2 = useRef(null)
    const RecoverWithUserName = async (e) => {
        e.preventDefault()
        const values = {
            orgno: orgno.current.value,
            username: username.current.value
        }
        const config = {
            method: 'post',
            url: '/auth/resetpassword',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values
        };

        const res = await axios_server(config)
        if (res?.data?.result === 'ok') setView('EmailSent')
        // window.alert(JSON.stringify(res, 0, 2))
    }
    const setNewPassord = async (e) => {
        e.preventDefault()
        const values = {
            recoverCode: rc,
            password: [pwd1.current.value, pwd2.current.value]
        }
        const config = {
            method: 'put',
            url: '/_mp/resetPassword',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values
        };

        
        //alert(JSON.stringify(values, null, 2))
        try {
            const res = await axios_server(config)
            setPopupMessage({info: 'Lösenordet ändrat', error: ''})
        } catch (error) {
            setPopupMessage({info: '', error: error.message})
        }
    }
    return <>
        {(view === 'form') && <>
            <div id="page-content-container">
                <h1>Glömt kontouppgifter</h1>

                <div className="fkrecover-info">
                    Om du har glömt ditt användarnamn eller lösenord fyller du i användarnamn eller organisationsnummer nedan.Tryck sedan på <i>Skicka</i>.
                    <br /><br />
                    Om du startar kontoåterställningen med användarnamn skickas ett meddelande till din e-postadress med instruktioner hur du fortsätter.
                    <br /><br />
                    Om du startar kontoåterställningen med organisationsnummer skickas ett meddelande till samtliga användarkontons registrerade e-postadresser.
                    <br /><br />
                    Man kan göra hur många kontoåterställningar som helst och inga ändringar görs förrän kontoåterställningen bekräftats enligt instruktionerna i e-postutskicket. Kontoåterställningen görs per användarkonto så det finns ingen risk att man råkar göra ändringar på något annat konto än sitt eget.
                </div><div style={{ clear: "both" }}></div>

                <div className="fkrecover-user">
                    <form onSubmit={e => RecoverWithUserName(e)} className="fkrecover-user">
                        <input type="hidden" name="option" value="com_fkrecover" />
                        <fieldset>
                            <legend>Starta kontoåterställning med användarnamn</legend>
                            <div className="fkrecover-user-row">
                                <div className="fkrecover-user-title">&nbsp;</div>
                                <input ref={username} name="username" type="text" />
                                <div className="fkrecover-user-info">Fyll i ditt användarnamn</div>
                            </div>

                            <div className="fkrecover-user-row">
                                <div className="fkrecover-user-title">&nbsp;</div>
                                <button type="submit">Skicka</button>
                            </div>
                        </fieldset>
                    </form>
                </div>

                <div className="fkrecover-user">
                    <form onSubmit={e => RecoverWithUserName(e)} className="fkrecover-user">
                        <input type="hidden" name="option" value="com_fkrecover" />
                        <fieldset>
                            <legend>Starta kontoåterställning med organisationsnummer</legend>
                            <div className="fkrecover-user-row">
                                <div className="fkrecover-user-title">&nbsp;</div>
                                <input ref={orgno} name="orgno" type="text" />
                                <div className="fkrecover-user-info">Fyll i ditt organisationsnummer. Numret ska bestå av tio siffror utan bindestreck eller bokstäver.</div>
                            </div>

                            <div className="fkrecover-user-row">
                                <div className="fkrecover-user-title">&nbsp;</div>
                                <button type="submit" >Skicka</button>
                            </div>
                        </fieldset>
                    </form>
                </div>

            </div>
        </>
        }
        {
            (view === 'EmailSent') && <>
                <h1>Epost skickad.</h1>
                <p>För att färdigställa aktiveringen, följ instruktionerna i meddelandet som har skickats till e-postadressen du angav. Aktiveringen måste slutföras inom tre dagar.</p>
            </>
        }
        {(view === 'rc') && <><h1>Nytt lösenord</h1>

            <div class="fkrecover-user">
                <form onSubmit={e => setNewPassord(e)} class="fkrecover-user">
                    <input type="hidden" name="rc" value="<?php if( isset( $this->email_code ) ) : echo $this->email_code; endif; ?>" />
                    <fieldset>
                        <legend>Nytt lösenord</legend>
                        <div class="fkrecover-user-row">
                            <div class="fkrecover-user-title">Lösenord</div>
                            <input ref={pwd1} name="password[]" type="password" />
                            <div class="fkrecover-user-info">
                                <p>Ange ditt önskade lösenord.</p>
                                <p>
                                    Lösenordet måste bestå av minst 12 tecken och kan bestå av <i>bokstäver</i>, <i>siffror</i> och <i>specialtecken(&nbsp;.,%$#@!&amp;?&nbsp;)</i>.
                                    Exempel: Gs.g!FkN?kÖ2
                                </p>
                                <p>
                                    <b>Godkända tecken:</b> A B C D E F G H I J K L M N O P Q R S T U V W X Y Z Å Ä Ö a b c d e f g h i j k l m n o p q r s t u v w x y z å ä ö 0 1 2 3 4 5 6 7 8 9 . , % $ # @ ! & ?
                                </p>
                            </div>
                        </div>

                        <div class="fkrecover-user-row">
                            <div class="fkrecover-user-title">&nbsp;</div>
                            <input ref={pwd2} name="password[]" type="password" />
                            <div class="fkrecover-user-info">Skriv lösenordet igen.</div>
                        </div>

                        <div class="fkrecover-user-row">
                            <div class="fkrecover-user-title">&nbsp;</div>
                            <input type="submit" value="Fortsätt" />
                        </div>
                    </fieldset>
                </form>
            </div>
        </>}
    </>
}
