import React, { useContext, useState } from 'react'
import { login, logout } from '../Client/client.js';
import { UserContext } from '../Client/UserContext.js'
import { PopupMessageContext } from '../Client/PopupMessageContect.js';
import '../Style/autofrontend.css'

export const Login = (props) => {
    const { user } = useContext(UserContext)

    return (user ? <LoggedIn /> : <LoginForm setErrorMessage={props.setErrorMessage} />);
}
const LoginForm = (props) => {
    const { user, setUser } = useContext(UserContext)
    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)
    const [username, setUsername] = useState(user?.name || '')
    const [password, setPassword] = useState('')
    const handleUsernameChange = event => {
        setUsername(event.target.value)
    }

    const handlePasswordChange = event => {
        setPassword(event.target.value)
    }
    return (
        <>
            <form autoComplete='off'>
                <label htmlFor="login_name">username</label>
                <input type="text" id="modlgn-username" name="login_name" onChange={handleUsernameChange} value={username} />
                <br />
                <label htmlFor="login_password">password</label>
                <input type="password" id="modlgn-passwd" name="login_password" onChange={handlePasswordChange} value={password} />
                <br />
            </form>
            <button id="modlgn-submit" onClick={async () => {
                const res = await login(username, password)
                if(res.status === 1) {
                    setUser(res.jwt)
                    setPopupMessage({info: '', error: ''})
                } else {
                    setPopupMessage({info: '', error: res.message})
                }
                
                
            }}>Logga In</button>
        </>
    )
}
const LoggedIn = () => {
    const { user, setUser } = useContext(UserContext)
    return (
    <>
        <div className="login-greeting">
	        Inloggad: {user.orgno}
            <br/>
            {user.orgname}	
        </div>
        
        <button onClick={async () => {
                const jwtPayload = await logout()
                setUser(jwtPayload)
            }}>
            Logout
        </button>
    </>
    )
}




