import React, { useRef, useState } from 'react'
import '../../Style/forms.css'
import './style.css'
import { axios_server } from '../../Client/client.js'
import { useParams } from 'react-router-dom'

export const SalarySystems = ({ params }) => {
    

    const style1 = {"text-align": "center"}

    return (
        <div id="page-content-container">
            <div class="item-page">
	            <h1>
	                Information för dig som tillverkar lönesystem
                </h1>
                <p>&nbsp;</p>
                <table style={{"border-width": "0px", "border-style": "solid", "margin-left": "12px", "border": "0", "align": "right"}}>
                    <tbody>
                        <tr>
                            <td style={style1}><a href="/files/bilaga3.pdf"><img src="/images/icons/48x48/mimetypes/application_pdf.png" alt=""/></a></td>
                        </tr>
                        <tr>
                            <td style={style1}><span style={{"font-size": "8pt"}}><a style={style1} href="/files/bilaga3.pdf">Exempelfil XML</a></span></td>
                        </tr>
                        <tr>
                            <td style={style1}><a style={style1} href="/files/dokument/Brev_till_foretag.pdf"><img style={{"border-style": "initial", "border-color": "initial"}} src="/images/icons/48x48/mimetypes/application_pdf.png" alt=""/></a></td>
                        </tr>
                        <tr>
                            <td style={style1}><a href="/files/dokument/Brev_till_foretag.pdf">Brev till företag</a></td>
                        </tr>
                        <tr>
                            <td style={style1}><a href="/files/dokument/maleriportalen_formatspec.zip"><img style={{"border-style": "initial", "border-color": "initial", "display": "block", "margin-left": "auto", "margin-right": "auto"}} src="/files/application_zip.png" alt=""/></a></td>
                        </tr>
                        <tr>
                            <td style={style1}><a href="/files/dokument/maleriportalen_formatspec.zip">Specifikation(zip)</a></td>
                        </tr>
                        <tr>
                            <td style={style1}><a href="/files/dokument/maleriportalen_formatspec.tar"><img src="/files/icons/48x48/mimetypes/application_x_ar.png" alt=""/></a>&nbsp;</td>
                        </tr>
                        <tr>
                            <td style={style1}><a href="/files/dokument/maleriportalen_formatspec.tar">Specifikation(tar)</a></td>
                        </tr>
                    </tbody>
                </table>
                <p>Filerna som skickas till Måleriportalen är av typen XML. Ladda ner specifikationer och exempelfiler för att anpassa ert lönesystem till att exportera löneuppgifter i Måleriportalens format.</p>
                <p>Om ert lönesystem exporterar uppgifter i Måleriportalens format kvalificerar systemet till att bli certifierat. Kontakta Måleriportalen.se för att få reda på mer information.</p> 
	        </div>
        </div>
    )

    
}
