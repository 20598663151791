import { Outlet } from "react-router-dom";
import Header from "../Components/Header.js"
import Footer from "../Components/Footer.js"
import '../Style/style.css'
import News from '../Components/News/News.js';
import { Login } from '../Components/Login.js';
import Menu from "../Components/Menu/Menu.js";
import bookImg from '../images/x_office_address_book.png'

import { PopupMessageContext } from '../Client/PopupMessageContect.js'

import { Link, useNavigate } from "react-router-dom";
import React, {useState, useContext} from 'react'

const Error_WrongCredentials = (props) => {

    const navigate = useNavigate();

    const send_state = {title: 'Lämna löneuppgifter', link: `index.php?option=com_monetform&view=uploadform`}

    return (
        <>

            Du har angivit fel användarnamn eller lösenord. Om du har fått kontouppgifter med brev men ännu inte aktiverat ditt konto: 
            <Link
                to="/index.php/aktivera-konto/"
                onClick={(e) => {
                    e.preventDefault()
                    navigate("/index.php/aktivera-konto/", 
                    { 
                        state: {
                            title: 'Aktivera konto', 
                            link: `index.php?option=com_fkactivate&view=activate`} 
                    })
                }}>
                    Aktivera&nbsp;konto
            </Link>

            <br/>Om du har glömt dina inloggningsuppgifter: 

            <Link 
                to="/index.php/glomt-kontouppgifter"
                onClick={(e) => {
                    e.preventDefault()
                    navigate("/index.php/glomt-kontouppgifter",
                    {
                        state: {
                            title: 'Glömt kontouppgifter',
                            link: `index.php?option=com_fkrecover&view=recover`
                        }
                    })
                }}>
                    Glömt&nbsp;inloggningsuppgifter
                </Link>

        </>
    )
}

const Error_BruteForce = (props) => {

    return (
        <>
            Skydd mot intrång. Försök igen om tio sekunder.
        </>
    )
}

//


const ErrorBox = (props) => {

    if(props.visibility !== 'true' || props.message === "") {
        return <></>
    }

    return (
        <>
            <div id="system-message-container">
                <dl id="system-message">
                <dt class="error">Fel</dt>
                    <dd class="error message">
                        <ul>
                            <li>
                                {props.message === "wrong username or password" ? <Error_WrongCredentials/> : props.message === "bruteforce" ? <Error_BruteForce/> : props.message}
                            </li>
                            <li></li>
                        </ul>
                    </dd>
                </dl>
            </div>
        </>
    )

    
}

const info_messages = ['Aktiveringsuppgifter har skickats till den angivna e-postadressen.']

/*
    Error, Felaktigt användarnamn
    Info, Aktiveringsuppgifter har skickats till den angivna e-postadressen.
    Error, Användarnamnet är upptaget.
*/

const InfoBox = (props) => {

    if(props.visibility !== 'true' || props.message === "") {
        return <></>
    }

    return (
        <>
        <div id="system-message-container">
            <dl id="system-message">
            <dt class="message">Meddelande</dt>
                <dd class="message message">
                    <ul>
                        <li>{ props.message }</li>
                    </ul>
                </dd>
            </dl>
        </div>
        </>
    )
}

const InfoBoxContext = React.createContext({info: '', error: ''})

const Layout = (props) => {

    const { popupMessage } = useContext(PopupMessageContext)
    return (
        <>
            <div className="page-middle">
                <div className="item-header">
                    <Header />
                </div>
                <div className="main-page">
                    <div className='left-column'>
                        <Menu menytype="mainmenu" />
                        <div className="custom">
                            <p style={{ textAlign: "center" }}><a href="/images/handbok.pdf"><img src={bookImg} alt="" /><br/>Handbok</a></p>
                        </div>
                    </div>
                    <div className="main-column">
                        <ErrorBox visibility='true' message={popupMessage.error}/>
                        <InfoBox visibility='true' message={popupMessage.info}/>
                        <Outlet />
                    </div>
                    <div className='right-column'>
                        <News limit='3' flash />
                    </div>
                    <div style={{ clear: 'both' }} />

                </div>
                <div className='login-box'><Login/></div>
                <div className="footer">
                    <Footer />
                </div>
            </div>
        </>
    )

}

export default Layout