// import PropTypes from 'prop-types'
import classNames from 'classnames'
import '../Style/pagination.css'
import './style.css'
// import { size } from './bulmaPropTypes'
// import { ReactChild } from 'react'
// type PaginationProps={
//   className?:string,
//   children?:ReactChild|ReactChild[],
//   rounded?:boolean,
//   size?:string,
//   centered?:boolean,
//   right?:boolean
// }
export const Pagination = ({
    className,
    children,
    rounded = false,
    size = '',
    centered = false,
    right = false,
    ...props
}) => {
    const cssClass = classNames(
        'pagination',
        { 'is-rounded': rounded },
        { 'is-centered': centered },
        { 'is-right': right },
        { [`is-${size}`]: size },
        className,
    )
    return (
        <nav className={cssClass} role="navigation" aria-label="pagination" {...props}>
            {children}
        </nav>
    )
}
// Pagination.propTypes = {
//   className: PropTypes.string,
//   children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
//   rounded: PropTypes.bool,
//   centered: PropTypes.bool,
//   right: PropTypes.bool,
//   size: size,
// }
export default Pagination
export const PaginationPrevious = ({ className, page, onClick, ...props }) => {
    const cssClass = classNames(
        'pagination-previous',
        className,
    )
    return <span className={cssClass} onClick={e => onClick(e)}>{page}</span>
}
// PaginationPrevious.propTypes = {
//   className: PropTypes.string,
//   page: PropTypes.string,
//   onClick: PropTypes.func,
// }
export const PaginationNext = ({ className, page, onClick, ...props }) => {
    const cssClass = classNames(
        'pagination-next',
        className,
    )
    return <span className={cssClass} onClick={e => onClick(e)}>{page}</span>
}
// PaginationNext.propTypes = {
//   className: PropTypes.string,
//   page: PropTypes.string,
//   onClick: PropTypes.func,
// }
// type PaginationListProps={
//   className?:string,
//   children: ReactChild|ReactChild[]
// }
export const PaginationList = ({ className, children, ...props }) => {
    const cssClass = classNames(
        'pagination-list',
        className,
    )
    return <ul className={cssClass}>{children}</ul>
}
// PaginationList.propTypes = {
//   className: PropTypes.string,
//   children: PropTypes.oneOfType([
//     PropTypes.element,
//     PropTypes.array,
//   ]),
// }
// type PaginationLinkProps={
//   className?:string,
//   children?: ReactChild,
//   page:number|string,
//   ariaLabel?:string,
//   active?:boolean,
//   disabled?:boolean,
//   onClick?:(e:any)=>void
// }
export const PaginationLink = ({
    className,
    page,
    ariaLabel,
    active = false,
    disabled = false,
    onClick,
    ...props
}) => {
    const cssClass = classNames(
        'pagination-link',
        { 'is-radiusless': active },
        { 'is-current': active },
        { 'link': !active },
        className,
    )

    return <li><span
        onClick={e => onClick(e)}
        className={cssClass}
        disabled={disabled}
        aria-label={ariaLabel || (active)
            ? `Page ${page}`
            : `Goto page ${page}`}
        aria-current={(active)
            ? 'page'
            : null} >{page}</span></li>
}
// PaginationLink.propTypes = {
//   className: PropTypes.string,
//   page: PropTypes.node,
//   ariaLabel: PropTypes.string,
//   active: PropTypes.bool,
//   onClick: PropTypes.func,
// }
// type PaginationEllipsisProps={
//   className?:string,
// }
export const PaginationEllipsis = ({ className, ...props }) => {
    const cssClass = classNames(
        'pagination-ellipsis',
        className,
    )
    return <li><span className={cssClass}>&hellip;</span></li>
}
// PaginationEllipsis.propTypes = {
//   className: PropTypes.string,
// }

//Implementation
export const specPage = {
    FIRST: 'FIRST',
    PREV: 'PREV',
    NEXT: 'NEXT',
    LAST: 'LAST',
}

export const MpPagination = ({
    count = 1,
    page = 1,
    onPageChange,
    centered = true,
    showFirstButton = false,
    showLastButton = false,
    siblingCount = 1,
    hideNextButton = false,
    hidePrevButton = false,
    boundaryCount = 1,
}) => {
    const roundedCount = Math.round(count + 0.5)
    if (roundedCount === 1) return null


    const pages = []
    let ellipsesAdded = false
    for (let index = 1; index < roundedCount + 1; index++) {
        if (
            (index === page) ||
            (index < (1 + boundaryCount) && index < page) ||
            (index > (roundedCount - boundaryCount) && index > page) ||
            (index >= (page - siblingCount) && index <= page) ||
            (index <= (page + siblingCount) && index >= page)) {
            pages.push(<PaginationLink key={index} page={index} active={(index === page)} onClick={e => {
                onPageChange(e, index)
            }} />)
            ellipsesAdded = false
        } else if (!ellipsesAdded) {
            pages.push(<PaginationEllipsis key={index} />)
            ellipsesAdded = true
        }
    }
    const handlePageChange = (e, value) => {
        switch (value) {
            case specPage.FIRST:
                onPageChange(e, 1)
                break
            case specPage.LAST:
                onPageChange(e, roundedCount + 1)
                break
            case specPage.PREV:
                if (page - 1 > 0) onPageChange(e, page - 1)
                break
            case specPage.NEXT:
                if (page + 1 <= roundedCount) onPageChange(e, page + 1)
                break

            default:
                break
        }
    }
    return (<Pagination centered>
        {
            /* <PaginationPrevious page='Previous'/>
                     <PaginationNext page='Next'/> */
        }
        <PaginationList>
            {showFirstButton && <PaginationLink page='|<' disabled={page === 1} onClick={e => handlePageChange(e, specPage.FIRST)} />}
            {!hidePrevButton && <PaginationLink page='<' disabled={page === 1} onClick={e => handlePageChange(e, specPage.PREV)} />}
            <>{pages}</>
            {/* <PaginationLink page='2' active />
      <PaginationEllipsis />
      <PaginationLink page='10' onClick={e => onPageChange(e, 10)}/> */}
            {!hideNextButton && <PaginationLink page='>' disabled={page > roundedCount} onClick={e => handlePageChange(e, specPage.NEXT)} />}
            {showLastButton && <PaginationLink page='>|' disabled={page === roundedCount} onClick={e => handlePageChange(e, specPage.LAST)} />}
        </PaginationList>
    </Pagination>)
}
