import React, {useContext} from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { ActivateAccount } from './ActivateAccount/ActivateAccount.js';
import { UploadForm } from './Uploadform/Uploadform.js';
import { UploadformDisabled} from './UploadformDisabled/UploadformDisabled.js'
import { Content } from './Content/Content.js'
import { Receiptlist } from './Receiptlist/Receiptlist.js';
import { UserEdit } from './UserEdit/UserEdit.js';
import { ProfileSettings } from './ProfileSettings/ProfileSettings.js';
import { PauseForm } from './Content/PauseForm/PauseForm.js';
import { Recover } from './Recover/Recover.js';
import { Larlingsportalen } from './Larlingsportalen/Larlingsportalen.js'
import { AdminView } from './AdminView/AdminView.js';
import { SalarySystems } from './SalarySystems/SalarySystems.js'
import News from '../Components/News/News.js';
import { axios_server } from '../Client/client.js';
import { useState, useEffect } from 'react';

import { PopupMessageContext } from '../Client/PopupMessageContect.js';


const StateFix = async (location) => {
    if(location.state === null) {
        
        const a = location.pathname.split('/').pop()
        const res = await axios_server.get(`/cms/menu/mainmenu`)
        
        //console.log("StateFix:", a)
        if(res.data?.recordset) {
            for (let i=0;i<res.data.recordset.length;i++){
                const item = res.data.recordset[i]
                if(a === item.alias){
                    //console.log("FOUND")
                    return item
                }
                
            }
        }
    }

    return StateFixFoot(location)

    //console.log("NOT FOUND")
    return null
}

const StateFixFoot = async (location) => {
    if(location.state === null) {
        const a = location.pathname.split('/').pop()
        const res = await axios_server.get(`/cms/menu/footer-menu`)
        
        if(res.data?.recordset) {
            for (let i=0;i<res.data.recordset.length;i++){
                const item = res.data.recordset[i]
                if(a === item.alias){
                    //console.log("FOUND")
                    return item
                }
                
            }
        }
    }

    return null
}

// temp until upload via form is fixed
const GetErrorList = async (filename) => {

    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `/mp/mpa002/getReceiptFile?filename=${filename}`,
        headers: { 
            'Content-Type': 'application/json'
        }
    };

    const res = await axios_server.request(config)
    const num_error = res.data?.file?.antalFel
    //console.log(res.data?.file)

    // skipping date for now, it seems that the files date format can have bad format!

    /*
    const dateString = res.data?.file?.datum
    const timeString = res.data?.file?.klockslag

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const hour = timeString.substring(0, 2);
    const minute = timeString.substring(2, 4);
    const second = timeString.substring(4, 6);
    const dateTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
    const formattedDateTime = dateTime.toISOString().replace('T', ' ').substring(0, 19);
    console.log(formattedDateTime)
    */

    if( num_error === "0" ) {
        console.log("GetErrorList", "0")
        return null
    } else if( num_error === "1" ){
        console.log("GetErrorList", "1")
        const error_list = [res.data?.file?.fel_lista?.fel]
        return error_list
    } else {
        console.log("GetErrorList", "many")
        const error_list = res.data?.file?.fel_lista?.fel
        return error_list
    }

}

export const IndexPHP = (props) => {

    const [myState, setMyState] = useState(null)
    const [myReceiptErrorList, setMyReceiptErrorList] = useState(null)
    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)

    // can't run conditionally!
    const p = useParams()
    const location = useLocation()

    useEffect(() => {
        async function fetchData() {
            
            const data = await StateFix(location)
            setMyState(data)
            if(option === 'com_monetform' && items.kvittens !== undefined){
                const error_list = await GetErrorList(items.kvittens)
                setMyReceiptErrorList(error_list)
            }

        }
        fetchData();
        setPopupMessage({info: '', error: ''})
    }, [window.location.href])

    const state = location.state === null ? myState : location.state
    if(location.state === null)
        location.state = myState
    const search = new URL(state?.link, 'http://localhost').searchParams.toString()
    let params = new URLSearchParams(`${location.search}&${search}`);
    const items = []
    for (const [key, value] of params.entries()) {
        items[key] = value
    }
    const option = params.get('option') || p.option

    if(option === 'com_monetform' && items.kvittens !== undefined) {

        const date = "" // disabled, it seems that files can have bad date format

        const divStyle1 = {
            float: 'left',
        }

        const divStyle2 = {
            'line-height': '32px',
            'padding-left': '76px'
        }

        const pStyle = {
            margin: 0,
            padding: 0
        }

        if(myReceiptErrorList === null){
            return <></>
        }
        return (
        <>
            <h1>Fel - Uppgifterna har inte lämnats</h1>
			<div style={divStyle1}><img alt="" src="https://www.maleriportalen.se/dev/components/com_monetform/images/error.png"/></div>
			<div class="monet">
                <div style={divStyle2}>
					<p style={pStyle}>Felet registrerades {date}</p>
					<p style={pStyle}>Bifoga den här listan om du kontaktar supporten.</p>
				</div>
                
					
				<h2>Fellista[{myReceiptErrorList === null ? 0 : myReceiptErrorList.length} fel]</h2>
                {myReceiptErrorList === null ? <></> : myReceiptErrorList.map((item, index) => {
                    console.log(item)
                    return <div class="monet-error"><h3>{index + 1}</h3><span class="monet-error-description">{item.feltext}</span></div>
                })}
			</div>
        </>
        )

        
    }

    if (option === 'com_fkuseredit' && items.ver !== undefined) {
        axios_server.post('/mp/mpa002/verifyEmail', { code: items.ver })
        return <Navigate to="/" />
    }

    if (option === 'com_fkactivate' && items.ver !== undefined) {
        axios_server.post('/_mp/verify_account', { code: items.ver })
        return <Navigate to="/" />
    }

    console.log(option) // ok

    switch (option) {
        case 'com_fkactivate':
            return <ActivateAccount {...items} />
        case 'com_fkrecover':
            return <Recover params={params} />
        case 'com_monetform':
            return <Monetform params={params} {...items} />
        case 'com_fkuseredit':
            return <ProfileSettings location={location} {...items} />
        case 'com_fkaddaccount':
            return <UserEdit location={location} {...items} />
        case 'com_apprenticelink':
            return <Larlingsportalen params={params} />
        case 'com_content':
            return <Content {...props} location={location} {...items} />
        case 'nyheter':
            return <News {...props} location={location} {...items} />
        case 'com_admin':
            return <AdminView params={params} {...items} />
        case 'com_lonesystem':
            return <SalarySystems params={params} {...items} />
        default:
            return <Unhandled myState={myState} {...items} />

    }

}

const Unhandled = ({ myState, ...items}) => {
    return <>
        <h1>Unhandled</h1>
    </>
}

function Monetform({ view, ...items }) {

    switch (view) {
        case 'uploadform':
            //return <UploadForm {...items}/>;
            return <UploadformDisabled {...items}/>
        case 'receiptlist':
            return <Receiptlist {...items}/>;
        case 'pause':
            return <PauseForm />;
        default:
            return <Unhandled {...items} />;
    }
}
