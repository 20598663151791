import React, { useContext } from 'react'
import '../../Style/forms.css'
import './style.css'
import { Form, Field } from 'react-final-form'
import { DebugComponent } from '../../Components/DebugComponent.js'
import { axios_server } from '../../Client/client.js'
import { FormInputField } from '../../Components/FormInputField.js'

import { PopupMessageContext } from '../../Client/PopupMessageContect.js'

const allowedPasswordChars = 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z Å Ä Ö a b c d e f g h i j k l m n o p q r s t u v w x y z å ä ö 0 1 2 3 4 5 6 7 8 9 . , % $ # @ ! & ?'.split(' ')
const NewPasswordInfo = `Ange ditt önskade lösenord. \n
 \n
Lösenordet måste bestå av minst 12 tecken och kan bestå av _bokstäver_, _siffror_ och _specialtecken( .,%$#@!&? )_. Exempel: Gs.g!FkN?kÖ2 \n
 \n
**Godkända tecken:** ${allowedPasswordChars.join(' ')}`
export const ActivateAccount = (props) => {

    const { popupMessage, setPopupMessage } = useContext(PopupMessageContext)

    const initialValues = { password: ['', ''], Username: '', email: ['', ''], ActivationCode: '' }
    const required = value => (value ? undefined : 'Obligatorisk')

    const validate = (values) => {
        const errors = { password: [], email: [] }
        
        if (values.password[0]) {
            if (values.password[0].length < 12) errors.password[0] = "Lösenordet för kort, minst 12 tecken."
        } else {
            errors.password[0] = "Lösenordet för kort, minst 12 tecken."
        }

        if (values.password[0] && values.password[1]) {
            if (values.password[0] !== values.password[1]) errors.password[0] = "Lösenordern skiljer."
        }

        if (values.password[0]) {
            const letters = values.password[0].split('')
            const unique = new Set(letters)
            unique.forEach(c => {
                if (allowedPasswordChars.indexOf(c) === -1) errors.password[0] = "Lösenordet innehåller ogiltigt tecken."
            });
        }

        return errors
    }
    const onSubmit = async (values) => {
        const config = {
            method: 'post',
            url: '/_mp/activateaccount',
            headers: {
                'Content-Type': 'application/json'
            },
            data: values
        };

        const res = await axios_server(config)
        .then(response=>{
            setPopupMessage({info: 'ok', error: ''})
            // reload page?
        })
        .catch(error=>{
            setPopupMessage({info: '', error: error.response.data.message})
            // reload page?
        })
        //window.alert(JSON.stringify(res, 0, 2))
    }
    return <>
        <h1>Aktivera konto</h1>

        <div className="fkactivate-info">
            <p>Fyll i nedstående formulär för att aktivera ditt konto.</p>
            <p>Användarnamn och Aktiveringskod står i brevutskicket.</p>
            <p><strong>Observera att du inte fyller i formuläret för att logga in. </strong>
                Logga in gör du genom att fylla i användarnamn och lösenord i fälten som markeras när
                du trycker på den blå knappen med texten Medlemsportal.</p>
        </div>
        <div style={{ clear: 'both' }}></div>

        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className="fkactivate-user">
                    <div className="fkactivate-user">
                        <fieldset>
                            <legend>Förtryckta uppgifter</legend>
                            <Field name="Username" validate={required}>{props => (<FormInputField title="Användarnamn" info="Ange det användarnamn du fått via brev." {...props} />)}</Field>
                            <Field name="ActivationCode" validate={required}>{props => (<FormInputField title="Aktiveringskod" field="" info="Ange den aktiveringskod du fått via brev." {...props} />)}</Field>
                        </fieldset>

                        <fieldset>
                            <legend>Användarkonto</legend>
                            <Field name="email.0" validate={required}>{props => (<FormInputField title="E-post" info="Ange din e-postadress."  {...props} />)}</Field>
                            <Field name="email.1" validate={required}>{props => (<FormInputField title={null} info="Ange din e-postadress igen." {...props} />)}</Field>

                            <div className="fkactivate-user-row">
                                <Field name="password.0" validate={required}>{props => (<FormInputField title="Nytt lösenord" type="password" info={NewPasswordInfo}  {...props} />)}</Field>
                                <Field name="password.1" validate={required}>{props => (<FormInputField title={null} info="Ange lösenord igen." type="password"  {...props} />)}</Field>
                            </div>
                        </fieldset>

                        <fieldset>
                            <div className="fkactivate-user-row">
                                <div className="fkactivate-user-title">Aktivera</div>
                                <input type="submit" value="Aktivera" />
                            </div>
                        </fieldset>
                    </div>
                    <input type="hidden" name="option" value="com_fkactivate" />
                    <DebugComponent values={values} />
                </form>
            )}
        />
    </>
}
