import { useLocation } from "react-router-dom"

export const DebugComponent = ({ values, visible = false, ...rest }) => {
    const location = useLocation()
    let params = new URLSearchParams(location.search);

    const debug = process.env.NODE_ENV || 'develop'
    if (!(debug === 'develop' || visible || params.has("debug"))) return null
    return (
        <pre>
            {JSON.stringify(values, null, 2)}
        </pre>
    )
}
