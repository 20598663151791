import React, { useContext } from "react"
import { UserContext } from '../../Client/UserContext.js'
import '../../Style/menu.css'
import classNames from 'classnames'
import { axios_server } from '../../Client/client.js'
import {
    Link,
    useLocation,
    useResolvedPath,
} from "react-router-dom"
// import {
//     useParams,
//     useResolvedPath
// } from "react-router-dom";
// import NewsFlashItem from "./NewsFlashItem";
export default function Menu(props) {
    const { user } = useContext(UserContext)

    const [data, setData] = React.useState(null)
    // let match = useMatch();
    // let path = useResolvedPath()
    // let params = useParams();
    React.useEffect(() => {
        axios_server.get(`/cms/menu/${props.menutype || 'mainmenu'}`).then((res) => {
            setData(res.data)
        })
    }, [props.menutype, user])

    return (
        <ul className='menu'>
            {
                data?.recordset?.map((n, index) => {
                    if (n.level !== 1) return null
                    const subItems = data.recordset.filter(item => (item.parent_id === n.id & n.level === 1))
                    return <MenuItem key={n.id} item={n} subItems={subItems} />
                })
            }
        </ul>
    )
}
const SubMenu = ({ subItems }) => {
    if (subItems?.length) return <ul>
        {
            subItems?.map((n, index) => {
                return <MenuItem key={n.id} item={n} />
            })
        }
    </ul>
    return null
}
const MenuItem = ({ item, subItems }) => {
    const to = (item.link.substring(0, 9) === 'index.php') ? `index.php/${item.alias}` : item.link
    let resolved = useResolvedPath(to);
    let location = useLocation()
    let match = location.pathname === resolved.pathname && location.search === resolved.search
    const css = classNames({
        [`item-${item.id}`]: true,
        current: match,
        active: match,
        deeper: subItems?.length,
        parent: subItems?.length
    })
    return <li className={css}><Link to={to} state={item || ''}>{item.title}</Link><SubMenu subItems={subItems} /></li>
}