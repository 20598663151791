import '../Style/footer.css'
import fora from '../images/fora.png'
import malerifakta from '../images/malerifakta.png'
import myn from '../images/myn.png'
function Footer() {
    return (
        <>
            <div className="footer-left footer-sub"><div className="footer-inner">
                <ul className="menu-footer" id="footer-menu">
                    <li className="item-112"><a href="/index.php/for-dig-som-tillverkar-lonesystem" style={{ color: 'rgb(102, 102, 102)' }}>För dig som tillverkar lönesystem</a></li><li className="item-225"><a href="/index.php/aktivera-anvandarkonto" style={{ color: 'rgb(102, 102, 102)' }}>Aktivera användarkonto</a></li><li className="item-226"><a href="/index.php/glomt-anvandarnamn-eller-losenord" style={{ color: 'rgb(102, 102, 102)' }}>Glömt användarnamn eller lösenord</a></li><li className="item-115"><a href="/index.php/kakor-pa-webbplatsen" style={{ color: 'rgb(102, 102, 102)' }}>Kakor på webbplatsen</a></li><li className="item-113"><a href="/index.php/kontakt" style={{ color: 'rgb(102, 102, 102)' }}>Kontakta oss</a></li></ul>
            </div></div>
            <div className="footer-middle footer-sub"><div className="footer-inner">

                <div className="custom-logos">
                    <p style={{ textAlign: 'center' }}><a href="http://www.fora.se/" style={{ textAlign: 'center' }}><img src={fora} alt="" style={{ borderBlockStyle: "initial", borderColor: "initial" }} /></a></p>
                    <p style={{ textAlign: 'center' }}><a href="http://www.malerifakta.se/"><img src={malerifakta} alt="" /></a></p>
                    <p><a href="http://www.malare.nu/"><img style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} src={myn} alt="" /></a></p></div>
            </div></div>
            <div className="footer-right footer-sub"><div className="footer-inner">

                <div className="custom">
                    <p>Copyright ©&nbsp;2011,2012</p>
                    <p>Måleriutveckling konto för forskning och utveckling</p></div>
            </div></div>
            <div style={{ clear: 'both' }}></div>
        </>
    )
}

export default Footer;