import React from 'react';
import ReactMarkdown from 'react-markdown';

export const FormInputField = ({ meta, input, title, info, type = 'text', ...props }) => {
    const BaseClass = props.baseClass || 'fkactivate-user'
    return (
        <div className={`${BaseClass}-row`}>
            <div className={`${BaseClass}-title`}>{title}</div>
            {meta.error && meta.touched && <div className="is-danger help">{meta.error}</div>}
            <input name={input.name}
                type={type}
                value={input.value}
                onChange={input.onChange} />

            <div className={`${BaseClass}-info`}><ReactMarkdown>{info}</ReactMarkdown></div>
        </div>
    );
};
